import { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import BasicModal from "./BasicModal";
import { useAuth } from "../../contextAPI/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeleteOrdersModal = ({ open, closeModal, orderId }) => {
  const { deleteOrders, authState, getOrderById } = useAuth();
  const { loading } = authState;
  const [ setCustomerData] = useState({});

  // Fetch order details by orderId
  const fetchOrder = async () => {
    if (orderId) {
      const response = await getOrderById(orderId);
      if (response.success) {
        setCustomerData(response?.data?.order);
      } else {
        toast.error(response.msg);
      }
    }
  };

  // Fetch order details when modal opens or orderId changes
  useEffect(() => {
    if (open && orderId) {
      fetchOrder();
    }
  }, [open, orderId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await deleteOrders(orderId);
    if (response.msg) {
      toast.success(response.msg);
      setTimeout(() => {
        closeModal();
      }, 2000); // Adjust the delay as needed
    }
  };

  return (
    <BasicModal open={open} closeModal={closeModal}>
      {/* <ToastContainer position="top-right" autoClose={3000} /> */}
      <div className="flex justify-between border-b-2 mb-2 pb-5 p-5 font-mono">
        <h2>Delete Customer Details: {orderId}</h2>
        <button onClick={closeModal}>
          <AiOutlineClose />
        </button>
      </div>

      <div className="bg-white p-2 transition-shadow lg:w-full md:block w-80">
        <ToastContainer position="top-right" autoClose={3000} />
        <div className="bg-white p-4 transition-shadow border rounded-md shadow-sm hover:shadow-lg">
          <div className="mb-4 flex gap-4">
            <h3 className="text-lg">
              Are you sure you want to delete this order from the database?
            </h3>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-end">
            <button
              type="submit"
              className="py-2 px-4 rounded-md bg-red-500 mt-4 text-white"
              disabled={loading}
            >
              {loading ? "Loading..." : "Delete customer details"}
            </button>
          </div>
        </form>
      </div>
    </BasicModal>
  );
};

export default DeleteOrdersModal;
