import { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import BasicModal from "./BasicModal";
import { useAuth } from "../../contextAPI/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StaffAcountUpdateModal = ({ open, closeModal, staffId }) => {
  const { editStaff, authState } = useAuth();
  const { loading } = authState;
  const [customerData, setCustomerData] = useState({});

  useEffect(() => {
    if (staffId) {
      const customer = authState.allStaff?.staff?.find((c) => c._id === staffId);
      if (customer) {
        setCustomerData(customer);
      }
    }
  }, [staffId, authState.allStaff]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await editStaff(staffId); // No payload needed
    if (response.success) {
      toast.success(response.msg);
      setTimeout(() => {
        closeModal();
      }, 2000); // Adjust the delay as needed
    } else {
      toast.error(response.msg);
    }
  };

  // Determine deactivate/reactivate text based on isActive
  const isReactivate = !customerData?.isActive;
  const actionText = isReactivate ? "re-activate" : "de-activate";
  const confirmationText = `Are you sure you want to ${actionText} 
    <span class="font-bold">${customerData?.fullName}</span> ${isReactivate ? "account?" : "from the system?"}`;

  // Determine button text and color based on isActive
  const buttonText = isReactivate ? "Re-activate staff account" : "De-activate staff account";
  const buttonColor = isReactivate ? "bg-green-500" : "bg-red-500";

  return (
    <BasicModal open={open} closeModal={closeModal}>
      <div className="flex justify-between border-b-2 mb-2 pb-5 p-5 font-mono">
        <h2>{isReactivate ? "Re-activate" : "De-activate"} Staff Account?</h2>
        <button onClick={closeModal}>
          <AiOutlineClose />
        </button>
      </div>

      <div className="bg-white p-2 transition-shadow lg:w-full md:block w-80">
        <ToastContainer position="top-right" autoClose={3000} />
        <div className="bg-white p-4 transition-shadow border rounded-md shadow-sm hover:shadow-lg">
          <div className="mb-4 flex gap-4">
            <h3 className="text-sm" dangerouslySetInnerHTML={{ __html: confirmationText }} />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-end">
            <button
              type="submit"
              className={`py-2 px-4 rounded-md ${buttonColor} mt-4 text-white text-sm`}
              disabled={loading}
            >
              {loading ? "Loading..." : buttonText}
            </button>
          </div>
        </form>
      </div>
    </BasicModal>
  );
};

export default StaffAcountUpdateModal;
