import React from "react";
import Layout from "./Layout";
import Index from "../../pages/dashboard/Index";
import CreateOrders from "../../pages/dashboard/CreateOrders";
import ViewAllStaff from "../../pages/dashboard/ViewAllStaff";
import Categories from "../../pages/dashboard/Categories";
import Notification from "../../pages/dashboard/Notifications"
import Customers from "../../pages/dashboard/Customers";
import ViewOrders from "../../pages/dashboard/ViewOrders";
import UserActivity from "../../pages/dashboard/UserActivity";
import OutstandingBalance from "../../pages/dashboard/OutstandingBalance";
import { useLocation } from "react-router-dom";

const LayoutProps = () => {
  const location = useLocation();

  // Extract the pathname from the location object
  const { pathname } = location;

  // Determine which component to render based on the route
  let contentComponent;
  if (pathname === "/dashboard") {
    contentComponent = <Index />;
  } else if (pathname === "/dashboard/create-order") {
    contentComponent = <CreateOrders />;
  } else if (pathname === "/dashboard/customers") {
    contentComponent = <Customers />;
  } else if (pathname === "/dashboard/notification") {
    contentComponent = <Notification />;
  } else if (pathname === "/dashboard/view-orders") {
    contentComponent = <ViewOrders />;
  } else if (pathname === "/dashboard/view-staff") {
    contentComponent = <ViewAllStaff />;
  } else if (pathname === "/dashboard/add-categories") {
    contentComponent = <Categories />;
  } else if (pathname === "/dashboard/user-activity") {
    contentComponent = <UserActivity />;
  } else if (pathname === "/dashboard/outstanding-balance") {
    contentComponent = <OutstandingBalance />;
  } else {
    // Handle other routes or set a default component
    contentComponent = null;
  }
  return (
    <>
      <Layout content={contentComponent} />

    </>
  );
};

export default LayoutProps;
