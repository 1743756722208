// SidebarMenuLink.js
import React from 'react';
import { Link } from 'react-router-dom';

function SidebarMenuLink({ to, icon, text, isSidebarOpen }) {
  return (
    <li>
      <Link
        to={to}
        className="font-mono text-white text-lg flex items-center py-3 p-1 space-x-1 rounded-md hover:bg-secondary hover:text-white"
      >
        <span>{icon}</span>
        <span>{text}</span>
        {/* <span className={!isSidebarOpen ? "hidden" : ""}>{text}</span> */}
      </Link>
    </li>
  );
}

export default SidebarMenuLink;
