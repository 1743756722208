import React, { createContext, useState, useContext, useCallback } from "react";
import axios from "axios";
import {
  AUTH_URL,
  USERPROFILE_URL,
  ADDCUSTOMERS_URL,
  LAUNDRYTYPES_URL,
  LAUNDRYITEMS_URL,
  ADDORDER_URL,
  GETALLORDERS_URL,
  GETORDERBYID_URL,
  EDITORDERS_URL,
  DELETEORDERS_URL,
  ADDSTAFF_URL,
  GETSTAFF_URL,
  UPDATESTAFF_URL,
  DASHBOARD_ANALYTICS,
  DASHBOARDSTAFF_ANALYTICS,
  FORGOT_PASSWORD,
  RESET_OTP,
  RESET_PASSWORD,
  GETALLBALANCE
} from "../config/config";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
    allcustomers: null,
    loading: false,
    deleteLoading: false,
    error: null,
    allBalance: null,
    token: localStorage.getItem("token"),
  });

  const login = async (email, password) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));

    try {
      const response = await axios.post(AUTH_URL, { email, password });

      if (response.status === 200 && response?.data?.token) {
        const token = response?.data?.token
        localStorage.setItem("token", token);
        localStorage.getItem("token");
        await fetchUserProfile(response?.data?.token);

        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          loading: false,
          error: null,
          token: response?.data?.token,
        }));

        return { success: true, data: response.data };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response?.data?.msg || "Login failed",
        }));
        return { success: false, msg: response?.data?.msg || "Login failed" };
      }
    } catch (error) {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.response?.data?.msg || "Login failed",
      }));
      throw error;
    }
  };


  const forgotPassword = async (email) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));

    try {
      const response = await axios.post(FORGOT_PASSWORD, { email });
      if (response.status === 200) {
        return { success: true, data: response.data };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response?.data?.msg,
        }));
        return { success: false, msg: response?.data?.msg };
      }
    } catch (error) {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.response?.data?.msg,
      }));
      throw error;
    }
  };

  const resetPassword = async (email, otp, password, confirmPassword) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));

    try {
      const response = await axios.post(RESET_PASSWORD, {
        email,
        otp,
        password,
        confirmPassword,
      });
      if (response.status === 200) {
        return { success: true, data: response.data };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response?.data?.msg,
        }));
        return { success: false, msg: response?.data?.msg };
      }
    } catch (error) {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.response?.data?.msg,
      }));
      throw error;
    }
  };

  const resetOtp = async (email) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));

    try {
      const response = await axios.post(RESET_OTP, { email });
      if (response.status === 201) {
        return { success: true, data: response.data };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response?.data?.msg,
        }));
        return { success: false, msg: response?.data?.msg };
      }
    } catch (error) {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.response?.data?.msg,
      }));
      throw error;
    }
  };

  const fetchUserProfile = useCallback(async (token) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));

    try {
      const response = await axios.get(USERPROFILE_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          user: response.data,
          loading: false,
          error: null,
        }));
      }
    } catch (error) {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.response?.data?.msg || "Failed to fetch user profile",
      }));
      // console.error("Error fetching user profile:", error);
      throw error;
    }
  }, []);

  const addCustomer = async (customerData) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(ADDCUSTOMERS_URL, customerData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: null,
        }));
        return { success: true, data: response.data, msg: response.data.msg };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response.data?.msg || "Failed to add customer",
        }));
        return {
          success: false,
          msg: response.data?.msg || "Failed to add customer",
        };
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg || "Failed to add customer";
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: errorMessage,
      }));
      // console.log(error, "error");
      // console.error("Error adding customer:", error);
      return { success: false, msg: errorMessage };
    }
  };

  const fetchAllCustomers = useCallback(async (token) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));

    try {
      const response = await axios.get(ADDCUSTOMERS_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log(response, "respnse")

      if (response.status === 201) {
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          allcustomers: response.data,
          loading: false,
          error: null,
        }));
      }
    } catch (error) {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.response?.data?.msg || "Failed to fetch all customers",
      }));
      throw error;
    }
  }, []);

  const editCustomer = async (id, customerData) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.patch(
        `${ADDCUSTOMERS_URL}/${id}`,
        customerData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: null,
        }));
        return { success: true, data: response.data, msg: response.data.msg };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response.data?.msg || "Failed to edit customer",
        }));
        return {
          success: false,
          msg: response.data?.msg || "Failed to edit customer",
        };
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg || "Failed to edit customer";
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: errorMessage,
      }));

      return { success: false, msg: errorMessage };
    }
  };

  const deleteCustomer = async (id) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.delete(`${ADDCUSTOMERS_URL}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: null,
        }));
        return { success: true, data: response.data, msg: response.data.msg };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response.data?.msg || "Failed to delete customer",
        }));

        return {
          success: false,
          msg: response.data?.msg || "Failed to delete customer",
        };
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg || "Failed to delete customer";
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: errorMessage,
      }));

      return { success: false, msg: errorMessage };
    }
  };

  const fetchLaundryTypes = useCallback(async (token) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));

    try {
      const response = await axios.get(LAUNDRYTYPES_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          laundryTypes: response.data,
          availableLaundryTypes: response.data,
          loading: false,
          error: null,
        }));
      }
    } catch (error) {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.response?.data?.msg || "Failed to fetch laundry types",
      }));
      throw error;
    }
  }, []);

  const addLaundryTypes = async (customerData) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(LAUNDRYTYPES_URL, customerData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 201) {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: null,
        }));
        return { success: true, data: response.data, msg: response.data.msg };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response.data?.msg || "Failed to add laundry types",
        }));
        return {
          success: false,
          msg: response.data?.msg || "Failed to add laundry types",
        };
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg || "Failed to add laundry types";
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: errorMessage,
      }));
      return { success: false, msg: errorMessage };
    }
  };

  const editLaundryTypes = async (id, customerData) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.patch(
        `${LAUNDRYTYPES_URL}/${id}`,
        customerData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: null,
        }));
        return { success: true, data: response.data, msg: response.data.msg };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response.data?.msg,
        }));
        return {
          success: false,
          msg: response.data?.msg,
        };
      }
    } catch (error) {
      const errorMessage = error.response?.data?.msg;
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: errorMessage,
      }));

      return { success: false, msg: errorMessage };
    }
  };

  const deleteLaundryTypes = async (id) => {
    setAuthState((prevState) => ({
      ...prevState,
      deleteLoading: true,
      error: null,
    }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.delete(`${LAUNDRYTYPES_URL}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          deleteLoading: false,
          error: null,
        }));
        return { success: true, data: response.data, msg: response.data.msg };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          deleteLoading: false,
          error: response.data?.msg || "Failed to delete types",
        }));

        return {
          success: false,
          msg: response.data?.msg || "Failed to delete types",
        };
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg || "Failed to delete types";
      setAuthState((prevState) => ({
        ...prevState,
        deleteLoading: false,
        error: errorMessage,
      }));

      return { success: false, msg: errorMessage };
    }
  };

  const fetchLaundryitems = useCallback(async (token) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));

    try {
      const response = await axios.get(LAUNDRYITEMS_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          laundryItems: response.data,
          availableLaundryItems: response.data,
          loading: false,
          error: null,
        }));
      }
    } catch (error) {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.response?.data?.msg || "Failed to fetch laundry types",
      }));
      throw error;
    }
  }, []);

  const addLaundryItems = async (customerData) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(LAUNDRYITEMS_URL, customerData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 201) {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: null,
        }));
        return { success: true, data: response.data, msg: response.data.msg };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response.data?.msg || "Failed to add laundry items",
        }));
        return {
          success: false,
          msg: response.data?.msg || "Failed to add laundry items",
        };
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg || "Failed to add laundry items";
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: errorMessage,
      }));
      return { success: false, msg: errorMessage };
    }
  };

  const editLaundryItem = async (id, customerData) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.patch(
        `${LAUNDRYITEMS_URL}/${id}`,
        customerData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: null,
        }));
        return { success: true, data: response.data, msg: response.data.msg };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response.data?.msg,
        }));
        return {
          success: false,
          msg: response.data?.msg,
        };
      }
    } catch (error) {
      const errorMessage = error.response?.data?.msg;
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: errorMessage,
      }));

      return { success: false, msg: errorMessage };
    }
  };

  const deleteLaundryItems = async (id) => {
    setAuthState((prevState) => ({
      ...prevState,
      deleteLoading: true,
      error: null,
    }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.delete(`${LAUNDRYITEMS_URL}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          deleteLoading: false,
          error: null,
        }));
        return { success: true, data: response.data, msg: response.data.msg };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          deleteLoading: false,
          error: response.data?.msg || "Failed to delete items",
        }));

        return {
          success: false,
          msg: response.data?.msg || "Failed to delete items",
        };
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg || "Failed to delete items";
      setAuthState((prevState) => ({
        ...prevState,
        deleteLoading: false,
        error: errorMessage,
      }));

      return { success: false, msg: errorMessage };
    }
  };

  const addNewOrder = async (customerData) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(ADDORDER_URL, customerData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: null,
        }));
        return { success: true, data: response.data, msg: response.data.msg };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response.data?.msg || "Failed to add new order",
        }));
        return {
          success: false,
          msg: response.data?.msg || "Failed to add new order",
        };
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg || "Failed to add new order";
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: errorMessage,
      }));
      return { success: false, msg: errorMessage };
    }
  };

  const fetchAllOrders = useCallback(async (token) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));

    try {
      const response = await axios.get(GETALLORDERS_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          allOrders: response.data,
          loading: false,
          error: null,
        }));
      }
    } catch (error) {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.response?.data?.msg,
      }));
      throw error;
    }
  }, []);

  const getOrderById = async (id) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(`${GETORDERBYID_URL}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          orderById: response.data,
          loading: false,
          error: null,
        }));
        return {
          success: true,
          data: response.data,
        };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response.data?.msg,
        }));
        return {
          success: false,
          msg: response.data?.msg,
        };
      }
    } catch (error) {
      const errorMessage = error.response?.data?.msg;
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: errorMessage,
      }));

      return { success: false, msg: errorMessage };
    }
  };
  const editOrders = async (id, customerData) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.patch(
        `${EDITORDERS_URL}/${id}`,
        customerData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: null,
        }));
        return { success: true, data: response.data, msg: response.data.msg };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response.data?.msg || "Failed to edit customer",
        }));
        return {
          success: false,
          msg: response.data?.msg || "Failed to edit customer",
        };
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg || "Failed to edit customer";
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: errorMessage,
      }));

      return { success: false, msg: errorMessage };
    }
  };
  const deleteOrders = async (id) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.delete(`${DELETEORDERS_URL}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: null,
        }));
        return { success: true, data: response.data, msg: response.data.msg };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response.data?.msg || "Failed to delete order",
        }));

        return {
          success: false,
          msg: response.data?.msg || "Failed to delete order",
        };
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg || "Failed to delete order";
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: errorMessage,
      }));

      return { success: false, msg: errorMessage };
    }
  };

  const addStaff = async (staffData) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(ADDSTAFF_URL, staffData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: null,
        }));
        return { success: true, data: response.data, msg: response.data.msg };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response.data?.msg || "Failed to add customer",
        }));
        return {
          success: false,
          msg: response.data?.msg || "Failed to add customer",
        };
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg || "Failed to add customer";
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: errorMessage,
      }));
      // console.log(error, "error");
      // console.error("Error adding customer:", error);
      return { success: false, msg: errorMessage };
    }
  };

  const fetchAllStaff = useCallback(async (token) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));

    try {
      const response = await axios.get(GETSTAFF_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          allStaff: response.data,
          loading: false,
          error: null,
        }));
      }
    } catch (error) {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.response?.data?.msg || "Failed to fetch all customers",
      }));
      throw error;
    }
  }, []);

  const editStaff = async (id) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.patch(
        `${UPDATESTAFF_URL}/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: null,
        }));
        return { success: true, data: response.data, msg: response.data.msg };
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
          error: response?.data?.msg,
        }));
        return {
          success: false,
          msg: response?.data?.msg,
        };
      }
    } catch (error) {
      const errorMessage = error.response?.data?.msg;
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: errorMessage,
      }));

      return { success: false, msg: errorMessage };
    }
  };

  const fetchDashboardAnalytics = useCallback(async (token) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));

    try {
      const response = await axios.get(DASHBOARD_ANALYTICS, {
        headers: { Authorization: `Bearer ${token}` },
      });
  

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          allDashboard: response.data,
          loading: false,
          error: null,
        }));
      }
    } catch (error) {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.response?.data?.msg,
      }));
      throw error;
    }
  }, []);

  const fetchStaffAnalytics = useCallback(async (token) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));

    try {
      const response = await axios.get(DASHBOARDSTAFF_ANALYTICS, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          allStaffAnalytics: response.data,
          loading: false,
          error: null,
        }));
      }
    } catch (error) {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.response?.data?.msg,
      }));
      throw error;
    }
  }, []);

  const fetchAllBalance = useCallback(async (token) => {
    setAuthState((prevState) => ({ ...prevState, loading: true, error: null }));

    try {
      const response = await axios.get(GETALLBALANCE, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          allBalance: response.data,
          loading: false,
          error: null,
        }));
      }
    } catch (error) {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.response?.data?.msg,
      }));
      throw error;
    }
  }, []);


  const logout = () => {
    localStorage.removeItem("token");
    setAuthState({
      isAuthenticated: false,
      user: null,
      loading: false,
      error: null,
      token: null,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        login,
        logout,
        fetchUserProfile,
        addCustomer,
        fetchAllCustomers,
        editCustomer,
        deleteCustomer,
        fetchLaundryTypes,
        fetchLaundryitems,
        addLaundryTypes,
        addLaundryItems,
        editLaundryTypes,
        editLaundryItem,
        deleteLaundryTypes,
        deleteLaundryItems,
        addNewOrder,
        fetchAllOrders,
        getOrderById,
        editOrders,
        deleteOrders,
        addStaff,
        fetchAllStaff,
        editStaff,
        fetchDashboardAnalytics,
        fetchStaffAnalytics,
        forgotPassword,
        resetPassword,
        resetOtp,
        fetchAllBalance
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
