import React, { useState, useEffect } from "react";
import AddStaffModal from "../../components/Modals/AddStaffModal";
import { useAuth } from "../../contextAPI/AuthContext";
import TanTable from "../../utils/TanTable";
import StaffAcountUpdateModal from "../../components/Modals/StaffAccountUpdateModal";
import Papa from "papaparse";
import { saveAs } from "file-saver";

const ViewAllStaff = () => {
  const [isAddStaffOpen, setIsAddStaffOpen] = useState(false);
  const [reloadCustomers, setReloadCustomers] = useState(false);
  const [isOpenAccount, setIsOpenAccount] = useState(false);
  const [selectedOrderId, setselectedOrderId] = useState(null);

  const openAddStaff = () => {
    setIsAddStaffOpen(true);
  };

  const closeModal = () => {
    setIsAddStaffOpen(false);
    setReloadCustomers((prev) => !prev);
  };

  const openAccount = (id) => {
    setselectedOrderId(id);
    setIsOpenAccount(true);
  };

  const closeAccountModal = () => {
    setIsOpenAccount(false);
    setReloadCustomers((prev) => !prev);
  };

  const { authState, fetchAllStaff } = useAuth();
  const { allStaff } = authState;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchAllStaff(token);
    }
  }, [fetchAllStaff, reloadCustomers]);

  // Sort the staff data
  const sortedStaff = allStaff?.staff?.sort((a, b) =>
    a.fullName.localeCompare(b.fullName)
  );

  const downloadCSV = () => {
    const csvData = sortedStaff.map((customer, index) => ({
      "S/N": index + 1,
      "Staff Name": customer.fullName,
      "Phone number": customer.phoneNumber,
      "Email": customer.email,
      "Role": customer.role,
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Swifttouchstaff.csv");
  };
  const columns = [
    {
      id: "S/N",
      header: "S/N",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      id: "Full Name",
      header: "Full Name",
      cell: (item) => {
        const currentItem = item.row.original;
        return <span className="capitalize">{currentItem?.fullName}</span>;
      },
    },
    {
      id: "Phone Number",
      header: "Phone Number",
      cell: (item) => {
        const currentItem = item.row.original;
        return <span className="capitalize">{currentItem?.phoneNumber}</span>;
      },
    },
    {
      id: "Email",
      header: "Email",
      cell: (item) => {
        const currentItem = item.row.original;
        return <span className="">{currentItem?.email}</span>;
      },
    },
    {
      id: "Role",
      header: "Role",
      cell: (item) => {
        const currentItem = item.row.original;
        return <span className="capitalize">{currentItem?.role}</span>;
      },
    },
    {
      id: "Action",
      header: "Action",
      cell: (item) => {
        const id = item.row.original._id;
        return (
          <button
            className="bg-primary py-2 rounded px-3 text-white"
            onClick={() => openAccount(id)}
          >
            Update account status
          </button>
        );
      },
    },
  ];

  if (!allStaff || !allStaff?.staff) {
    return <div className="text-center py-4 font-bold text-lg">Loading...</div>;
  }

  return (
    <div className="flex font-mono">
      <div className="flex flex-col flex-1">
        <main className="flex-1 max-h-full p-5 overflow-hidden overflow-y-scroll">
          {/* Main content header */}
          <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row">
            <h1 className="text-2xl font-semibold whitespace-nowrap text-primary">
              View Employees
            </h1>
            <div className="space-y-6 md:space-x-2 md:space-y-0">
              <div
                className="cursor-pointer inline-flex items-center justify-center px-4 py-2 space-x-1 bg-primary text-white rounded-md shadow hover:bg-opacity-20"
                onClick={openAddStaff}
              >
                <span>
                  <span>
                    <svg
                      className="w-4 h-4 text-white"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 0C3.58 0 0 3.58 0 8c0 4.42 3.58 8 8 8 4.42 0 8-3.58 8-8 0-4.42-3.58-8-8-8zm4 9H9v3H7V9H4V7h3V4h2v3h3v2z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </span>
                <span>Add a new staff</span>
              </div>
            </div>
          </div>
          <AddStaffModal open={isAddStaffOpen} closeModal={closeModal} />

          {/* Start Content */}

          {/* Table */}
          <div className="overflow-x-auto mt-4">
            <div className="flex justify-end">
              <div
                className="cursor-pointer inline-flex items-center justify-center text-primary
      px-4 py-2 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20"
                onClick={downloadCSV}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      strokeWidth="2"
                      fill="transparent"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 6v6m0 0v6m0-6l-4-4m4 4l4-4"
                    />
                  </svg>
                </span>
                <span>Download CSV</span>
              </div>
            </div>

            <div className="min-w-full overflow-hidden border rounded-md shadow-md mt-2">
              <TanTable columnData={columns} data={sortedStaff} />
            </div>
          </div>
        </main>
      </div>
      <StaffAcountUpdateModal
        open={isOpenAccount}
        closeModal={closeAccountModal}
        staffId={selectedOrderId}
      />
    </div>
  );
};

export default ViewAllStaff;
