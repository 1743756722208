// config.js
// BASE URL
export const BASE_URL = process.env.REACT_APP_BASE_URL;
//authenticate user
export const AUTH_URL = `${BASE_URL}auth/login`;
//forget password
export const FORGOT_PASSWORD = `${BASE_URL}auth/forget_password`;
//Reset Password
export const RESET_PASSWORD = `${BASE_URL}auth/reset_password`;
//Resend otp
export const RESET_OTP = `${BASE_URL}auth/resend_otp`;
//get userprofile
export const USERPROFILE_URL = `${BASE_URL}auth/user`;
//add customers
export const ADDCUSTOMERS_URL = `${BASE_URL}customer`;
//get laundry types
export const LAUNDRYTYPES_URL = `${BASE_URL}laundry/types`;
//get laundry services 
export const LAUNDRYITEMS_URL = `${BASE_URL}laundry/items`;
//add new order
export const ADDORDER_URL = `${BASE_URL}order/create`;
//get all orders 
export const GETALLORDERS_URL = `${BASE_URL}order/get-all`;
//get orders by id
export const GETORDERBYID_URL = `${BASE_URL}order/get`;
//edit orders 
export const EDITORDERS_URL = `${BASE_URL}order/update`;
//delete orders
export const DELETEORDERS_URL = `${BASE_URL}order/delete`;
//add new staff
export const ADDSTAFF_URL = `${BASE_URL}auth/register/staff`;
//get all staff
export const GETSTAFF_URL = `${BASE_URL}auth/all-staff`;
//upade staff status
export const UPDATESTAFF_URL = `${BASE_URL}auth/change-status`;
//upade staff status
export const DASHBOARD_ANALYTICS = `${BASE_URL}analytics/admin`;
//upade staff status
export const DASHBOARDSTAFF_ANALYTICS = `${BASE_URL}analytics`;
//outstandingbalance 
export const GETALLBALANCE = `${BASE_URL}order/get-all/outstanding`;


