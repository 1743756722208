import React, { useState } from "react";
import loginImage from "../../assets/logo/login-img.png";
import Logo from "../../assets/logo/swifttouch-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contextAPI/AuthContext";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { authState, login } = useAuth();
  const { loading } = authState;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Please fill out all fields");
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid Email format");
      return;
    }

    if (password.length < 6) {
      toast.error("Password must be at least 6 characters");
      return;
    }

    try {
      const response = await login(email, password);
      if (response.success) {
        toast.success("Log In Successfully");
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000); // 1 second delay
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error(error.response?.data?.msg || 'An error occurred while logging in');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden md:w-5/6">
        <div className="md:w-1/2 hidden md:block">
          <img
            src={loginImage}
            alt="Register"
            className="w-full h-auto md:h-full"
          />
        </div>
        <div className="md:w-1/2 p-8">
          <img src={Logo} alt="logo" className="w-64" />
          <h2 className="text-xl font-semibold font-mono mb-4">Welcome Back</h2>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block mb-1 font-mono">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-primary rounded-lg placeholder-gray-400 text-sm"
                placeholder="Enter your email address"
              />
            </div>
            <div>
              <label htmlFor="password" className="block mb-1 font-mono">Password</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-primary rounded-lg placeholder-gray-400 text-sm pr-10"
                  placeholder="Enter your Password"
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="absolute top-0 right-0 mt-3 mr-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                  size="sm"
                  style={{ color: "#028795" }}
                />
              </div>
            </div>
            <div className="flex justify-end mt-4 font-mono">
              <Link to="/forgot-password">
                <span className="text-primary text-sm">Forgot Password?</span>
              </Link>
            </div>
            <button
              type="submit"
              className="font-mono w-full bg-primary text-white py-2 rounded-full hover:bg-white hover:border border-primary hover:text-primary disabled:opacity-50"
              disabled={loading}
            >
              {loading ? "Loading..." : "Log In"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
