import React from "react";
import ViewAllOrdersTable from "../../components/dashboard/ViewAllOrdersTable";

const ViewOrders = () => {
 

  return (
    <div className="flex font-mono">
      <div className="flex flex-col flex-1">
        <main className="flex-1 max-h-full p-5 overflow-hidden overflow-y-scroll">
          {/* Main content header */}
          <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row">
          <h1 className="text-2xl font-semibold whitespace-nowrap text-primary">
             View Orders
            </h1>
       
          </div>

          {/* Start Content */}

          {/* Table */}
          <ViewAllOrdersTable/>
          
        </main>
      </div>
    </div>
  );
};

export default ViewOrders;
