import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";


const Layout = ({ content }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    getSidebarStateFromLocalStorage()
  );

  function getSidebarStateFromLocalStorage() {
    return JSON.parse(window.localStorage.getItem("isSidebarOpen")) || false;
  }

  function setSidebarStateToLocalStorage(value) {
    window.localStorage.setItem("isSidebarOpen", JSON.stringify(value));
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setSidebarStateToLocalStorage(!isSidebarOpen);
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      {/* <Sidebar /> */}
      <div className="flex flex-col flex-1 overflow-hidden">
        <Header />
        <main className="flex-1 overflow-y-auto bg-gray-100">
          {content}
        </main>
      </div>
    </div>
  );
};

export default Layout;
