import React, { useState } from "react";
import ViewOutstandingBalance from "../../components/dashboard/OutstandingBalanceListTable";

const OutstandingBalance = () => {
  const [reloadCustomers, setReloadCustomers] = useState(false);



  return (
    <div className="flex font-mono">
      <div className="flex flex-col flex-1">
        <main className="flex-1 max-h-full p-5 overflow-hidden overflow-y-scroll">
          <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row">
            <h1 className="text-2xl font-semibold whitespace-nowrap text-primary">
              View Outstanding Balance
            </h1>
            
          </div>
          <ViewOutstandingBalance
            reloadCustomers={reloadCustomers}
            setReloadCustomers={setReloadCustomers}
          />

        </main>
      </div>
    </div>
  );
};

export default OutstandingBalance;
