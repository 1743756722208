// import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import BasicModal from "./BasicModal";
import { useAuth } from "../../contextAPI/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

const AddLaundryItemsModal = ({ open, closeModal }) => {
  const { addLaundryItems, authState } = useAuth();
  const { loading } = authState;
  const [laundryData, setLaundryData] = useState({
    item: "",
    price: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLaundryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addLaundryItems(laundryData);

      if (response.msg === "New laundry item created successfully") {
        toast.success(response.msg, {
          onClose: () => {
            closeModal();
            setLaundryData({
              item: "",
            });
          },
        });
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error(error.response.msg || "An error occurred");
    }
  };

  return (
    <BasicModal open={open} closeModal={closeModal}>
      <div className="flex justify-between border-b-2 mb-2 pb-5 p-5 font-mono">
        <h2>Add Laundry Items</h2>
        <button onClick={closeModal}>
          <AiOutlineClose />
        </button>
      </div>

      <div className="bg-white p-2 transition-shadow  lg:w-full md:block w-80 ">
        <ToastContainer position="top-right" autoClose={3000} />
        <div className="bg-white p-4 transition-shadow border rounded-md shadow-sm hover:shadow-lg">
          <form className="p-3 font-mono" onSubmit={handleSubmit}>
          <div className="mb-4 flex gap-4">
              <div className="w-1/2">
                <label
                  htmlFor="item"
                  className="block text-sm font-medium text-gray-700"
                >
                  Laundry Item
                </label>
                <div className="flex mt-1">
                  <input
                    type="text"
                    id="item"
                    name="item"
                    value={laundryData.item}
                    onChange={handleChange}
                    className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                    placeholder="Enter the laundry item"
                    required
                  />
                </div>
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="price"
                  className="block text-sm font-medium text-gray-700"
                >
                  Price
                </label>
                <div className="flex mt-1">
                  <input
                    type="number"
                    id="price"
                    name="price"
                    value={laundryData.price}
                    onChange={handleChange}
                    className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                    placeholder="Enter the price"
                    required
                  />
                </div>
              </div>
            </div>
            
            <button
               type="submit"
              className="py-2 px-4 rounded-md bg-primary w-full text-white"
              disabled={loading}
            >
              {loading ? "Loading..." : "Add a new laundry item"}
            </button>
          </form>
        </div>
      </div>
    </BasicModal>
  );
};

export default AddLaundryItemsModal;
