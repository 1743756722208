import React, { useEffect } from "react";
import { useAuth } from "../../contextAPI/AuthContext";
import { Link } from "react-router-dom";

const Index = () => {
  const {
    authState,
    fetchUserProfile,
    fetchDashboardAnalytics,
    fetchStaffAnalytics,
    fetchAllOrders,
  } = useAuth();
  const { user, allDashboard, allStaffAnalytics, allOrders } = authState;
  const dashboardData = allDashboard;
  const staffDashboardData = allStaffAnalytics;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchUserProfile(token);
      fetchStaffAnalytics(token);
      fetchAllOrders(token);

      if (user?.role === "admin") {
        fetchDashboardAnalytics(token);
      }
    }
  }, [
    fetchUserProfile,
    fetchDashboardAnalytics,
    fetchStaffAnalytics,
    fetchAllOrders,
    user?.role, // Ensure the effect reruns when the user's role changes
  ]);

  // Format totalWithVAT with commas and two decimal places
  const formattedTotal = dashboardData?.totalRevenue?.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  // Check if the user is an admin
  const isAdmin = user?.role === "admin";

  // let sortedOrders = [];
  // if (allOrders && allOrders.orders) {
  //   sortedOrders = allOrders.orders.sort(
  //     (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  //   );
  // }

  let sortedOrders = [];
  if (allOrders && allOrders.orders) {
    sortedOrders = allOrders.orders
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      .slice(0, 5); // Get the top 5 recent orders
  }
  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return new Date(`${year}-${month}-${day}`);
  };

  const today = new Date();
  const startOfToday = new Date(today.setHours(0, 0, 0, 0));
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const dailyOrders = allOrders?.orders?.filter((order) => {
    const orderDate = parseDate(order?.dropOffDate);
    // console.log(orderDate, "orderdate")
    return orderDate >= startOfToday;
  });

  const monthlyOrders = allOrders?.orders?.filter((order) => {
    const orderDate = parseDate(order?.dropOffDate);
    return orderDate >= startOfMonth;
  });
 

  const calculateTotalAmount = (orders) => {
    return orders?.reduce(
      (total, order) => total + (order?.totalAmount || 0),
      0
    );
  };

  const dailyTotalAmount = calculateTotalAmount(dailyOrders);
  const monthlyTotalAmount = calculateTotalAmount(monthlyOrders);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString();
  };

  // Function to get the status class based on the order status
  const getStatusClass = (status) => {
    switch (status) {
      case "pending":
        return "bg-orange-500 text-white";
      case "in-progress":
        return "bg-yellow-500 text-white";
      case "completed":
        return "bg-green-500 text-white";
      default:
        return "bg-gray-500 text-white"; // Default class for unknown statuses
    }
  };

  return (
    <div className="flex font-mono">
      <div className="flex flex-col flex-1">
        <main className="flex-1 max-h-full p-5 overflow-hidden overflow-y-scroll">
          {/* Main content header */}
          <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row">
            <h1 className="text-2xl font-semibold whitespace-nowrap text-primary">
              {isAdmin ? "Admin Dashboard" : "Staff Dashboard"}
            </h1>
          </div>

          {/* Start Content */}
          <div className="grid grid-cols-1 gap-5 mt-6 sm:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg flex justify-center lg:w-full md:block w-80 lg:flex justify-center">
              <div className="flex items-start justify-between">
                <Link to="/dashboard/view-orders">
                  <div className="flex flex-col space-y-2 cursor-pointer">
                    <span className="text-primary font-semibold text-sm">
                      Total Orders
                    </span>
                    <span className="text-3xl text-center text-primary">
                      {isAdmin
                        ? dashboardData?.totalOrders
                        : staffDashboardData?.totalOrders}
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="bg-white p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg flex justify-center lg:w-full md:block w-80 lg:flex justify-center">
              <div className="flex items-start justify-between">
                <div className="flex flex-col space-y-2">
                  <span className="text-orange-500 font-semibold text-sm">
                    Pending Orders
                  </span>
                  <span className="text-3xl text-center text-orange-500">
                    {isAdmin
                      ? dashboardData?.pendingOrders
                      : staffDashboardData?.pendingOrders}
                  </span>
                </div>
              </div>
            </div>

            <div className="bg-white p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg flex justify-center lg:w-full md:block w-80 lg:flex justify-center">
              <div className="flex items-start justify-between">
                <div className="flex flex-col space-y-2">
                  <span className="text-yellow-500 font-semibold text-sm">
                    In-Progress Orders
                  </span>
                  <span className="text-3xl text-center text-yellow-500">
                    {isAdmin
                      ? dashboardData?.inProgressOrders
                      : staffDashboardData?.inProgressOrders}
                  </span>
                </div>
              </div>
            </div>

            <div className="bg-white p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg flex justify-center lg:w-full md:block w-80 lg:flex justify-center">
              <div className="flex items-start justify-between">
                <div className="flex flex-col space-y-2">
                  <span className="text-green-500 font-semibold text-sm">
                    Completed Orders
                  </span>
                  <span className="text-3xl text-center text-green-500">
                    {isAdmin
                      ? dashboardData?.completedOrders
                      : staffDashboardData?.completedOrders}
                  </span>
                </div>
              </div>
            </div>

            <div className="bg-white p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg flex justify-center lg:w-full md:block w-80 lg:flex justify-center">
              <div className="flex items-start justify-between">
                <Link to="/dashboard/customers">
                  <div className="flex flex-col space-y-2 cursor-pointer">
                    <span className="text-gray-500 font-semibold text-sm">
                      No. of Customers
                    </span>
                    <span className="text-3xl text-center text-gray-500">
                      {isAdmin
                        ? dashboardData?.customers
                        : staffDashboardData?.customers}
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            {/* <div className="bg-white p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg flex justify-center lg:w-full md:block w-80 lg:flex justify-center">
              <div className="flex items-start justify-between">
                <div className="flex flex-col space-y-2">
                  <span className="text-indigo-700 font-semibold text-sm">
                    Total Amount of Daily Orders
                  </span>
                  <span className="text-3xl text-center text-indigo-700">
                    {dailyTotalAmount?.toLocaleString("en-NG", {
                      style: "currency",
                      currency: "NGN",
                    })}
                  </span>
                </div>
              </div>
            </div> */}

            <div className="bg-white p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg flex justify-center lg:w-full md:block w-80 lg:flex justify-center">
              <div className="flex items-start justify-between">
                <Link to="/dashboard/outstanding-balance">
                  <div className="flex flex-col space-y-2 cursor-pointer">
                    <span className="text-red-600 font-semibold text-sm">
                      Outstanding Balance
                    </span>

                    <span className="text-3xl text-center text-red-600">
                      <span className="text-3xl text-center text-red-600">
                         {isAdmin
                          ? dashboardData?.outstandingBalance
                            ? dashboardData.outstandingBalance.toLocaleString(
                                "en-NG",
                                {
                                  style: "currency",
                                  currency: "NGN",
                                }
                              )
                            : "₦0.00"
                          : staffDashboardData?.outstandingBalance
                          ? staffDashboardData.outstandingBalance.toLocaleString(
                              "en-NG",
                              {
                                style: "currency",
                                currency: "NGN",
                              }
                            )
                          : "₦0.00"} 
                      </span>
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            {/* Additional analytics for admin */}
            {isAdmin && (
              <>
                <div className="bg-white p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg flex justify-center lg:w-full md:block w-80 lg:flex justify-center">
                  <div className="flex items-start justify-between">
                    <Link to="/dashboard/view-staff">
                      <div className="flex flex-col space-y-2 cursor-pointer">
                        <span className="text-primary font-semibold text-sm">
                          No. of Staff
                        </span>
                        <span className="text-3xl text-center text-primary">
                          {dashboardData?.staff}
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="bg-white p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg flex justify-center lg:w-full md:block w-80 lg:flex justify-center">
                  <div className="flex items-start justify-between">
                    <div className="flex flex-col space-y-2">
                      <span className="text-rose-800 font-semibold text-sm">
                        No. of De-activated Staff
                      </span>
                      <span className="text-3xl text-center text-rose-800">
                        {dashboardData?.deactivatedStaff}
                      </span>
                    </div>
                  </div>
                </div>
{/* 
                <div className="bg-white p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg flex justify-center lg:w-full md:block w-80 lg:flex justify-center">
                  <div className="flex items-start justify-between">
                    <div className="flex flex-col space-y-2">
                      <span className="text-amber-500 font-semibold text-sm">
                        Total Amount of Monthly Orders
                      </span>
                      <span className="text-3xl text-center text-amber-500">
                        {monthlyTotalAmount?.toLocaleString("en-NG", {
                          style: "currency",
                          currency: "NGN",
                        })}
                      </span>
                    </div>
                  </div>
                </div> */}

                <div className="bg-white p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg flex justify-center lg:w-full md:block w-80 lg:flex justify-center">
                  <div className="flex items-start justify-between">
                    <div className="flex flex-col space-y-2">
                      <span className="text-black font-semibold text-sm">
                        Total Revenue
                      </span>
                      <span className="text-3xl text-center text-black">
                        {formattedTotal}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Books completed */}
          <div className="bg-white mt-6 border rounded-md shadow-md p-4">
            <div className="flex items-center justify-between">
              <h3 className="text-2xl font-bold text-primary">Recent Orders</h3>
              <div className="text-sm text-primary py-2 cursor-pointer">
                <Link to="/dashboard/view-orders">View All</Link>
              </div>
            </div>
            <div className="overflow-x-auto mt-4">
              <div className="min-w-full overflow-hidden border rounded-md shadow-md">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        Customer Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        Phone Number
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        Laundry Item
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        Total Amount
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        Order Status
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        Pickup Date
                      </th>

                      <th scope="col" className="relative px-3 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                      <th scope="col" className="relative px-3 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {sortedOrders?.map((order) => (
                      <tr
                        key={order?._id}
                        className="transition-all hover:bg-gray-100 hover:shadow-lg"
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm">{order?.dropOffDate}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium capitalize">
                            {order?.customer?.fullName}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium capitalize">
                            {order?.customer?.phoneNumber}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium capitalize">
                            <ul>
                              {order?.laundryItems?.map(
                                (laundryItem, index) => (
                                  <li key={index}>
                                    {truncateText(
                                      `${laundryItem?.item?.item}`,
                                      20
                                    )}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium capitalize">
                            {order?.totalAmount.toLocaleString("en-NG", {
                              style: "currency",
                              currency: "NGN",
                            })}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div
                            className={`text-sm font-medium capitalize px-2 
                              py-1 rounded text-center ${getStatusClass(
                                order.status
                              )}`}
                          >
                            {order.status}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium capitalize">
                            {formatDate(order?.readyDate)}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Index;
