
import { useState } from 'react';
import BasicDialog from '../components/Modals/BasicDialog';

export default function ModalTest () {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <div className='flex justify-center mt-12 font-bold text-2xl'>
      <button onClick={openDialog}>Open Dialog</button>
      <BasicDialog isOpen={isOpen} onClose={closeDialog} title="Example Dialog">
        This is a simple example of a dialog component using `@headlessui/react`.
      </BasicDialog>
    </div>
  );
};