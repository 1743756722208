import React, { useState} from "react";
import loginImage from "../../assets/logo/login-img.png";
import Logo from "../../assets/logo/swifttouch-logo.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../contextAPI/AuthContext";


const ResendOTP = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  const { email} = formData;
  const { authState, resetOtp } = useAuth();
  const { loading } = authState;


 
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please fill out all fields");
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid Email format");
      return;
    }

    try {
      const response = await resetOtp(email);
      if (response.success) {
        toast.success(response?.data?.msg);
        setTimeout(() => {
          navigate("/reset-password");
        }, 2000); // 2 second delay
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.msg || "An error occurred"
      );
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
         <ToastContainer position="top-right" autoClose={3000} />
      <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden md:w-5/6">
        {/* Left side with image */}
        <div className="md:w-1/2 hidden md:block">
          <img
            src={loginImage}
            alt="Register"
            className="w-full h-auto md:h-full"
          />
        </div>

        {/* Right side with registration form */}
        <div className="md:w-1/2 p-8">
          <img src={Logo} alt="logo" className="w-64" />
          <div className="flex items-center mb-2">
            <h2 className="text-xl font-semibold font-mono"> Reset Password</h2>

            <div className="ml-auto" onClick={handleGoBack}>
              <span className="text-primary">
                <FontAwesomeIcon icon={faArrowLeft} />
              </span>
            </div>
          </div>
         
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block mb-1 font-mono">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-primary rounded-lg placeholder-gray-400 text-sm"
                placeholder="Enter your email address"
              />
            </div>
           
            <button
              type="submit"
              className="font-mono w-full bg-primary text-white py-2 rounded-full hover:bg-white hover:border border-primary hover:text-primary disabled:opacity-50"
              disabled={loading}
            >
              {loading ? "Loading..." : "Resend Otp"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResendOTP;
