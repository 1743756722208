import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import BasicModal from "./BasicModal";
import { useAuth } from "../../contextAPI/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddCustomersModal = ({ open, closeModal }) => {
  const { addCustomer, authState } = useAuth();
  const { loading } = authState;
  const [customerData, setCustomerData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    address: "",
    city: "",
    lga: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addCustomer(customerData);

      if (response.msg === "Customer created succesfully") {
        toast.success(response.msg, {
          onClose: () => {
            closeModal();
            setCustomerData({ // Clear the form after successfully adding a customer
              fullName: "",
              email: "",
              phoneNumber: "",
              address: "",
              city: "",
              lga: "",
            });
          }
        });
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error(error.response.msg || "An error occurred");
    }
  };
  return (
    <BasicModal open={open} closeModal={closeModal}>
      {/* <ToastContainer position="top-right" autoClose={3000} /> */}
      <div className="flex justify-between border-b-2 mb-2 pb-5 p-5 font-mono">
        <h2>Add New Customers</h2>
        <button onClick={closeModal}>
          <AiOutlineClose />
        </button>
      </div>

      <div className="bg-white p-2 transition-shadow lg:w-full md:block w-80">
        <ToastContainer position="top-right" autoClose={3000} />
        <div className="bg-white p-4 transition-shadow border rounded-md shadow-sm hover:shadow-lg">
          <form className="p-3 font-mono" onSubmit={handleSubmit}>
            <div className="mb-4 flex gap-4">
              <div className="w-1/2">
                <label
                  htmlFor="fullName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Customer Name
                </label>
                <div className="flex mt-1">
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    value={customerData.fullName}
                    onChange={handleChange}
                    className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                    placeholder="Enter the customer name"
                    required
                  />
                </div>
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
                <div className="flex mt-1">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={customerData.email}
                    onChange={handleChange}
                    className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                    placeholder="Enter the customer email address"
                  
                  />
                </div>
              </div>
            </div>
            <div className="mb-4 flex gap-4">
              <div className="w-1/2">
                <label
                  htmlFor="phoneNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <div className="flex mt-1">
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={customerData.phoneNumber}
                    onChange={handleChange}
                    maxLength={11}
                    className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                    placeholder="Enter the customer phone number"
                   
                  />
                </div>
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Home Address
                </label>
                <div className="flex mt-1">
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={customerData.address}
                    onChange={handleChange}
                    className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                    placeholder="Enter the customer home address"
                   
                  />
                </div>
              </div>
            </div>
            <div className="mb-4 flex gap-4">
              <div className="w-1/2">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  State
                </label>
                <div className="flex mt-1">
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={customerData.city}
                    onChange={handleChange}
                    className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                    placeholder="Enter the city"
                    required
                  />
                </div>
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="lga"
                  className="block text-sm font-medium text-gray-700"
                >
                  Local Govt.
                </label>
                <div className="flex mt-1">
                  <input
                    type="text"
                    id="lga"
                    name="lga"
                    value={customerData.lga}
                    onChange={handleChange}
                    className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                    placeholder="Enter the local govt."
                    required
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="py-2 px-4 rounded-md bg-primary w-full text-white"
              disabled={loading}
            >
              {loading ? "Loading..." : "   Add a new customer"}
            </button>
          </form>
        </div>
      </div>
    </BasicModal>
  );
};

export default AddCustomersModal;
