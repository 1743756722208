import React, { useState, useEffect } from 'react';

// Function to get the formatted date
const getFormattedDate = () => {
  const date = new Date();
  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  // Add suffix to day
  const day = date.getDate();
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return formattedDate.replace(/\d+/, `${day}${daySuffix(day)}`);
};

const DateComponent = () => {
  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    setCurrentDate(getFormattedDate());
  }, []);

  return (
    <div className="flex items-center 
    bg-white rounded-full shadow px-2 py-2">
      <svg
        className="w-4 h-4 text-primary mr-2"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 7V3M16 7V3M3 11H21M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div className="text-sm font-bold text-primary ">
        {currentDate}
      </div>
    </div>
  );
};

export default DateComponent;
