import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import BasicModal from "./BasicModal";
import { useAuth } from "../../contextAPI/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewOrdersModal = ({ open, closeModal, orderId }) => {
  const { getOrderById, editOrders, fetchLaundryitems, fetchLaundryTypes } =
    useAuth();

  const [customerData, setCustomerData] = useState({
    status: "",
    laundryItems: [],
    amountPaid:"",
  });

  // Fetch order details by orderId
  const fetchOrder = async () => {
    if (orderId) {
      const response = await getOrderById(orderId);
      if (response.success) {
        setCustomerData(response?.data?.order);
        // console.log(response?.data?.order, "order");
      } else {
        toast.error(response.msg);
      }
    }
  };
  const formattedTotal = customerData?.totalAmount?.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
  });
  const formattedAdditionalFee = customerData?.additionalFee?.toLocaleString(
    "en-NG",
    {
      style: "currency",
      currency: "NGN",
    }
  );
  const formattedLogisticsFee = customerData?.logisticFee?.toLocaleString(
    "en-NG",
    {
      style: "currency",
      currency: "NGN",
    }
  );
  const formattedAmountPaid = customerData?.amountPaid?.toLocaleString(
    "en-NG",
    {
      style: "currency",
      currency: "NGN",
    }
  );
  const totalAmount = customerData?.totalAmount;
  const amountPaidByClient = customerData?.amountPaid;
  const OutstandingBalance =
    Number(totalAmount) - Number(amountPaidByClient || 0);

  const formattedOutstandingBalance = OutstandingBalance?.toLocaleString(
    "en-NG",
    {
      style: "currency",
      currency: "NGN",
    }
  );
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString();
  };
  const totalPrice = customerData?.laundryItems?.reduce((acc, item) => {
    return acc + (item?.quantity || 0) * (item?.price || 0);
  }, 0);
  const readyDateOrder = formatDate(customerData?.readyDate);
  // Fetch laundry items and types on component mount
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchLaundryitems(token);
      fetchLaundryTypes(token);
    }
  }, [fetchLaundryitems, fetchLaundryTypes]);

  // Fetch order details when modal opens or orderId changes
  useEffect(() => {
    if (open && orderId) {
      fetchOrder();
    }
  }, [open, orderId]);

  // Function to get the status class based on the order status
  const getStatusClass = (status) => {
    switch (status) {
      case "pending":
        return "bg-orange-500 text-white";
      case "in-progress":
        return "bg-yellow-500 text-white";
      case "completed":
        return "bg-green-500 text-white";
      default:
        return "bg-gray-500 text-white"; // Default class for unknown statuses
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      status: customerData.status,
      laundryItems: customerData.laundryItems,
    };
    const response = await editOrders(orderId, payload);

    if (response.success) {
      toast.success(response.msg);
      setTimeout(() => {
        closeModal();
      }, 2000); // Adjust the delay as needed
    } else {
      toast.error(response.msg);
    }
  };

  return (
    <BasicModal open={open} closeModal={closeModal}>
      <div className="flex justify-between border-b-2 mb-2 pb-5 p-5 font-mono">
        <h2>View Order Details</h2>
        <button onClick={closeModal}>
          <AiOutlineClose />
        </button>
      </div>

      <div className="bg-white p-2 transition-shadow lg:w-full md:block w-80">
        <ToastContainer position="top-right" autoClose={3000} />
        <div className="bg-white p-4 transition-shadow border rounded-md shadow-sm hover:shadow-lg">
          <form className="p-3 font-mono" onSubmit={handleSubmit}>
            <div className="mb-4 flex gap-4">
              <div className="w-1/2">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium text-gray-700"
                >
                  Customer Name{" "}
                </label>
                <div className="flex mt-1 capitalize">
                  {" "}
                  {customerData?.customer?.fullName}
                </div>
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number{" "}
                </label>
                <div className="flex mt-1 capitalize">
                  {" "}
                  {customerData?.customer?.phoneNumber}
                </div>
              </div>
            </div>
            <div className="mb-4 flex gap-4">
              <div className="w-1/2">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium text-gray-700"
                >
                  Order Status{" "}
                </label>
                <div
                  className={`inline-block w-46 px-2 py-1 rounded text-center  ${getStatusClass(
                    customerData?.status
                  )}`}
                >
                  {customerData?.status}
                </div>
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="laundryQty"
                  className="block text-sm font-medium text-gray-700"
                >
                  Discount
                </label>
                <div className="flex mt-1">
                  {customerData?.discount !== null ? (
                    <div>{customerData?.discount} %</div>
                  ) : (
                    <div>0 %</div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="mb-4 flex gap-4">
                <div className="w-1/2">
                  <label
                    htmlFor="laundryQty"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Payment Method
                  </label>
                  <div className="flex mt-1">
                  {customerData?.paymentMethod ? (
                      <div>{customerData?.paymentMethod}</div>
                    ) : (
                      <div>None</div>
                    )}</div>
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="laundryQty"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Payment Type
                  </label>
                  <div className="flex mt-1 capitalize">
                    {customerData?.paymentType}
                  </div>
                </div>
              </div>
              <div className="mb-4 flex gap-4">
                <div className="w-1/2">
                  <label
                    htmlFor="laundryQty"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Additional Fee
                  </label>
                  <div className="flex mt-1">
                    {customerData?.additionalFee !== null ? (
                      <div>{formattedAdditionalFee}</div>
                    ) : (
                      <div>0</div>
                    )}
                  </div>
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="laundryQty"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Logistics Fee
                  </label>
                  <div className="flex mt-1">
                    {customerData?.logisticFee !== null ? (
                      <div>{formattedLogisticsFee}</div>
                    ) : (
                      <div>0</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="mb-4 flex gap-4">
                <div className="w-1/2">
                  <label
                    htmlFor="laundryQty"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Drop off date
                  </label>
                  <div className="flex mt-1">{customerData?.dropOffDate}</div>
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="laundryQty"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Pickup date
                  </label>
                  <div className="flex mt-1">{readyDateOrder}</div>
                </div>
              </div>
              <div className="mb-4 flex gap-4">
                {customerData?.paymentType === "part payment" && (
                  <div
                    className={`${
                      customerData?.paymentType === "no payment"
                        ? "w-full"
                        : "w-1/2"
                    }`}
                  >
                    <label
                      htmlFor="amountpaid"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Amount Paid
                    </label>
                    <div className="flex mt-1 border-2 border-green-500 rounded-md px-4 py-2 font-bold text-green-500">
                      {formattedAmountPaid}
                    </div>
                  </div>
                )}

                {(customerData?.paymentType === "part payment" ||
                  customerData?.paymentType === "no payment") && (
                  <div
                    className={`${
                      customerData?.paymentType !== "part payment"
                        ? "w-full"
                        : "w-1/2"
                    }`}
                  >
                    <label
                      htmlFor="outstandingbalance"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Outstanding Balance
                    </label>
                    <div className="flex mt-1 border-2 border-red-500 rounded-md px-4 py-2 font-bold text-red-500">
                      {formattedOutstandingBalance}
                    </div>
                  </div>
                )}
              </div>
             

              <div className="mb-4 flex gap-4">
                <div className="w-full">
                  <label
                    htmlFor="laundryQty"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Total Amount
                  </label>
                  <div className="flex mt-1 border-2 border-primary rounded-md px-4 py-2 font-bold text-primary">
                    {formattedTotal}
                  </div>
                </div>
              </div>

              <div className="mb-4 flex gap-4">
                <div className="w-full">
                  <label
                    htmlFor="laundryItem"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Laundry Item - {totalPrice.toLocaleString("en-NG", { style: "currency", currency: "NGN" })}
                  </label>
                  <div className="mt-1 grid grid-cols-1 gap-4">
                    {customerData?.laundryItems?.map((item) => (
                      <div
                        key={item.item._id}
                        className="border-2 border-primary rounded-md px-4 py-2"
                      >
                        {item?.item?.item}({item?.quantity})({item?.price.toLocaleString("en-NG", { style: "currency", currency: "NGN" })})
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* <div className="mb-4 flex gap-4">
                <div className="w-full">
                  <label
                    htmlFor="laundryService"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Laundry Service
                  </label>
                  <div className="flex mt-1 gap-2">
                    {customerData?.laundryItems?.map((type) => (
                      <div
                        key={type.type._id}
                        className="border-2 border-primary rounded-md px-4 py-2"
                      >
                        {type?.type?.type}
                      </div>
                    ))}
                  </div>
                </div>
              </div> */}
            </div>
          </form>
        </div>
      </div>
    </BasicModal>
  );
};

export default ViewOrdersModal;
