import React, { useState, useEffect } from "react";
import TanTable from "../../utils/TanTable";
import { useAuth } from "../../contextAPI/AuthContext";
import EditCustomersModal from "../Modals/EditCustomersModal";
import DeleteCustomersModal from "../Modals/DeleteCustomersModal";
import Papa from "papaparse";
import { saveAs } from "file-saver";

export default function ViewAllCustomersTable({
  reloadCustomers,
  setReloadCustomers,
}) {
  const { authState, fetchAllCustomers } = useAuth();
  const { allcustomers, user } = authState;
  const [isAddCustomersOpen, setIsAddCustomersOpen] = useState(false);
  const [isDeleteCustomersOpen, setIsDeleteCustomersOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [filterNameOrPhone, setFilterNameOrPhone] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchAllCustomers(token);
    }
  }, [fetchAllCustomers, reloadCustomers]);

  let sortedCustomers = [];
  if (allcustomers && allcustomers.customers) {
    sortedCustomers = allcustomers.customers.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
  }

  const openAddCustomers = (id) => {
    setSelectedCustomerId(id);
    setIsAddCustomersOpen(true);
  };

  const openDeleteCustomers = (id) => {
    setSelectedCustomerId(id);
    setIsDeleteCustomersOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteCustomersOpen(false);
    setSelectedCustomerId(null);
    setReloadCustomers((prev) => !prev);
  };

  const closeModal = () => {
    setIsAddCustomersOpen(false);
    setSelectedCustomerId(null);
    setReloadCustomers((prev) => !prev);
  };

  const filteredCustomers = sortedCustomers.filter((order) => {
    const customerName = order?.fullName?.toLowerCase() || "";
    const customerPhone = order?.phoneNumber || "";
    const customerEmail = order?.email || "";

    const matchesNameOrPhoneOrDate =
      !filterNameOrPhone ||
      customerName.includes(filterNameOrPhone.toLowerCase()) ||
      customerPhone.includes(filterNameOrPhone) ||
      customerEmail.includes(filterNameOrPhone);

    return matchesNameOrPhoneOrDate;
  });

  const downloadCSV = () => {
    const csvData = filteredCustomers.map((customer, index) => ({
      "S/N": index + 1,
      Date: new Date(customer.updatedAt).toLocaleDateString(),
      "Customer Name": customer.fullName,
      "Phone number": customer.phoneNumber,
      "Email": customer.email,
      "Address": customer.address,
      "City": `${customer.lga}, ${customer.city}`,
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Swifttouchcustomers.csv");
  };

  const columns = [
    {
      id: "S/N",
      header: "S/N",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      id: "Date",
      header: "Date",
      cell: (item) => {
        const currentItem = item.row.original;
        const formattedDate = new Date(
          currentItem.updatedAt
        ).toLocaleDateString();
        return <span className="capitalize">{formattedDate}</span>;
      },
    },
    {
      id: "Customer Name",
      header: "Customer Name",
      cell: (item) => {
        const currentItem = item.row.original;
        return <span className="capitalize">{currentItem.fullName}</span>;
      },
    },
    {
      id: "Phone number",
      header: "Phone number",
      cell: (item) => {
        const currentItem = item.row.original;
        return <span className="capitalize">{currentItem.phoneNumber}</span>;
      },
    },
    {
      id: "Email",
      header: "Email",
      cell: (item) => {
        const currentItem = item.row.original;
        return <span className="">{currentItem.email}</span>;
      },
    },
    {
      id: "Address",
      header: "Address",
      cell: (item) => {
        const currentItem = item.row.original;
        return <span className="">{currentItem.address}</span>;
      },
    },
    {
      id: "City",
      header: "City",
      cell: (item) => {
        const currentItem = item.row.original;
        return (
          <span className="">{`${currentItem.lga},${currentItem.city}`}</span>
        );
      },
    },
    {
      id: "Actions",
      header: "Actions",
      cell: (item) => {
        const id = item.row.original._id;
        return (
          <div className="flex space-x-1 text-sm">
            <button
              className="bg-primary text-white py-1 px-3 rounded-md hover:bg-opacity-75"
              onClick={() => openAddCustomers(id)}
            >
              Edit
            </button>
            {user?.role === "admin" && (
              <button
                className="bg-red-500 text-white py-1 px-3 rounded-md hover:bg-opacity-75"
                onClick={() => openDeleteCustomers(id)}
              >
                Delete
              </button>
            )}
          </div>
        );
      },
    },
  ];

  if (!allcustomers || !allcustomers.customers) {
    return <div className="text-center py-4 font-bold text-lg">Loading...</div>;
  }

  return (
    <>
      <div className="filter-container p-4 flex justify-between">
        {/* Left side: Search input */}
        <input
          type="text"
          value={filterNameOrPhone}
          onChange={(e) => setFilterNameOrPhone(e.target.value)}
          placeholder="Search..."
          className="rounded-md border-2 border-primary p-2 focus:border-primary
                focus:outline-none focus:ring-1"
        />

        {/* Right side: Filter select and Download CSV button */}
        <div className="flex items-center space-x-4">
          {/* Download CSV button */}
          {user?.role === "admin" && (
            <div
              className="cursor-pointer inline-flex items-center justify-center text-primary
        px-4 py-2 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20"
              onClick={downloadCSV}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    stroke-width="2"
                    fill="transparent"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6v6m0 0v6m0-6l-4-4m4 4l4-4"
                  />
                </svg>
              </span>
              <span>Download CSV</span>
            </div>
          )}
        </div>
      </div>
      <TanTable columnData={columns} data={filteredCustomers} />
      <EditCustomersModal
        open={isAddCustomersOpen}
        closeModal={closeModal}
        customerId={selectedCustomerId}
        setReloadCustomers={setReloadCustomers}
      />
      <DeleteCustomersModal
        open={isDeleteCustomersOpen}
        closeModal={closeDeleteModal}
        customerId={selectedCustomerId}
        setReloadCustomers={setReloadCustomers}
      />
    </>
  );
}
