import { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import BasicModal from "./BasicModal";
import { useAuth } from "../../contextAPI/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditLaundryTypeModal = ({ open, closeModal, laundryItem }) => {
  const { editLaundryTypes, deleteLaundryTypes, authState } = useAuth();
  const { loading, deleteLoading } = authState;
  const [laundryData, setLaundryData] = useState({
    item: "",
    price: "",
  });

  useEffect(() => {
    if (laundryItem) {
      setLaundryData(laundryItem);
    }
  }, [laundryItem]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLaundryData({ ...laundryData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await editLaundryTypes(laundryItem._id, laundryData);

    if (response.success) {
      toast.success(response.msg);
      setTimeout(() => {
        closeModal();
      }, 2000); // Adjust the delay as needed
    }
  };

  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    const response = await deleteLaundryTypes(laundryItem._id);

    if (response.success) {
      toast.success(response.msg);
      setTimeout(() => {
        closeModal();
      }, 2000); // Adjust the delay as needed
    }
  };

  return (
    <BasicModal open={open} closeModal={closeModal}>
      <div className="flex justify-between border-b-2 mb-2 pb-5 p-5 font-mono">
        <h2>Edit Laundry Services Details</h2>
        <button onClick={closeModal}>
          <AiOutlineClose />
        </button>
      </div>

      <div className="bg-white p-2 transition-shadow lg:w-full md:block w-80">
        <ToastContainer position="top-right" autoClose={3000} />
        <div className="bg-white p-4 transition-shadow border rounded-md shadow-sm hover:shadow-lg">
          <form className="p-3 font-mono">
            <div className="mb-4 flex gap-4">
              <div className="w-full">
                <label
                  htmlFor="item"
                  className="block text-sm font-medium text-gray-700"
                >
                  Laundry Item
                </label>
                <div className="flex mt-1">
                  <input
                    type="text"
                    id="laundryItem"
                    name="type"
                    value={laundryData.type}
                    onChange={handleChange}
                    className="mt-1 p-2 border rounded-md w-full border border-green-300 hover:border-green-500 focus:outline-none"
                    placeholder="Enter the Laundry Services"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="py-2 px-4 rounded-md text-xs bg-primary text-white"
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? "Loading..." : "Edit laundry services"}
              </button>

              <button
                type="button"  // Change to "button" to prevent form submission
                className="bg-red-500 text-white py-2 px-4 rounded-md text-xs ml-2"
                onClick={handleDeleteSubmit}
                disabled={deleteLoading}
              >
                {deleteLoading ? "Loading..." : "Delete laundry services"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </BasicModal>
  );
};

export default EditLaundryTypeModal;
