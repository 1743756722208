import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import Select from "react-select";
import PreviewInvoice from "./PreviewInvoice";
import { useAuth } from "../../contextAPI/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GenerateReceipts from "../../components/Modals/GenerateReceipt";

const CreateOrders = () => {
  const {
    authState,
    fetchAllCustomers,
    fetchLaundryitems,
    fetchLaundryTypes,
    addNewOrder,
  } = useAuth();
  const navigate = useNavigate();
  const { allcustomers, laundryItems, loading } = authState;
  const [customerType, setCustomerType] = useState("");
  const [productionDays, setProductionDays] = useState("");
  const [otherProductionDays, setOtherProductionDays] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [pickupDate, setPickupDate] = useState("");
  const [dropOffDate, setDropOffDate] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [vat] = useState("");
  const [discount, setDiscount] = useState("");
  const [additionalFee, setadditionalFee] = useState("");
  const [logisticFee, setLogisticFee] = useState("");
  const [setIsAddCustomersOpen] = useState(false);
  const [showReceiptModal] = useState(false); // State to control receipt modal visibility
  // const [orderId, setOrderId] = useState(null);
  const [orderDetails, setOrderDetails] = useState([
    {
      id: 1,
      selectedLaundryItem: null,
      selectedLaundryType: null,
      quantity: 0,
      costPrice: "",
    },
  ]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchAllCustomers(token);
      fetchLaundryitems(token);
      fetchLaundryTypes(token);
    }
  }, [fetchAllCustomers, fetchLaundryitems, fetchLaundryTypes]);

  const handleCustomerTypeChange = (event) => {
    setCustomerType(event.target.value);
  };

  const handleLaundryItemChange = (selectedOption, index) => {
    const updatedOrderDetails = [...orderDetails];
    updatedOrderDetails[index].selectedLaundryItem = selectedOption;
    if (selectedOption.value !== "others") {
      updatedOrderDetails[index].customLaundryItem = "";
    }
    // Find the selected laundry item from laundryItems array
    const selectedItem = laundryItems.find(
      (item) => item._id === selectedOption.value
    );

    // Update the costPrice in orderDetails with the price of the selected laundry item
    if (selectedItem) {
      updatedOrderDetails[index].costPrice = selectedItem.price; // Assuming selectedItem has a price field
    } else {
      updatedOrderDetails[index].costPrice = ""; // Handle case where selectedItem is not found
    }

    updatedOrderDetails[index].selectedLaundryType = null; // Reset laundry type when item changes
    setOrderDetails(updatedOrderDetails);
  };

  const handleIncrement = (index) => {
    const updatedOrderDetails = [...orderDetails];
    updatedOrderDetails[index].quantity++;
    setOrderDetails(updatedOrderDetails);
  };

  const handleDecrement = (index) => {
    const updatedOrderDetails = [...orderDetails];
    if (updatedOrderDetails[index].quantity > 1) {
      updatedOrderDetails[index].quantity--;
      setOrderDetails(updatedOrderDetails);
    }
  };

  const handleAddMoreOrder = () => {
    setOrderDetails([
      ...orderDetails,
      {
        id: orderDetails.length + 1,
        selectedLaundryItem: null,
        selectedLaundryType: null,
        quantity: 1,
        costPrice: "",
      },
    ]);
  };

  const handleRemoveLastOrderDetail = () => {
    if (orderDetails.length > 1) {
      const updatedOrderDetails = orderDetails.slice(0, -1);
      setOrderDetails(updatedOrderDetails);
    }
  };

  const isOrderDetailEmpty = (detail) => {
    return (
      !detail.selectedLaundryItem ||
      // !detail.selectedLaundryType ||
      !detail.quantity ||
      !detail.costPrice
    );
  };

  const isAnyOrderDetailEmpty = () => {
    return orderDetails.some(isOrderDetailEmpty);
  };

  const handleProductionChange = (event) => {
    const value = event.target.value;
    setProductionDays(value);
    if (value !== "other") {
      setOtherProductionDays("");
      calculatePickupDate(value);
    }
  };

  const handleOtherProductionChange = (event) => {
    const value = event.target.value;
    setOtherProductionDays(value);
    if (value) {
      calculatePickupDate("other", value);
    }
  };

  const calculatePickupDate = (days, otherDays = null) => {
    const currentDate = new Date();
    let newPickupDate;
  
    if (days === "0") {
      // For "Same day", set the pickup date to the current date
      newPickupDate = new Date(currentDate); // Clone the current date
    } else {
      const daysToAdd =
        days === "other"
          ? parseInt(otherDays, 10)
          : parseInt(days, 10); // Directly parse the days value
      
      // Create a new date object to avoid modifying the original currentDate
      newPickupDate = new Date(currentDate.getTime()); 
      newPickupDate.setDate(currentDate.getDate() + daysToAdd);
    }
  
    setPickupDate(formatDate(newPickupDate)); // Format and set the pickup date
  };
  
  // const calculatePickupDate = (days, otherDays = null) => {
  //   const currentDate = new Date();
  //   let newPickupDate;
  //   if (days === "0") {
  //     newPickupDate = currentDate;
  //   } 
    
  //   else {
  //     const daysToAdd =
  //       days === "other"
  //         ? parseInt(otherDays, 10)
  //         : parseInt(days.split("-")[0], 10);
  //     newPickupDate = new Date(
  //       currentDate.setDate(currentDate.getDate() + daysToAdd)
  //     );
  //   }
  //   setPickupDate(formatDate(newPickupDate));
  // };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDropOffDateChange = (e) => {
    const date = new Date(e.target.value);
    const formattedDate = date.toLocaleDateString("en-GB"); // DD/MM/YYYY format
    setDropOffDate(formattedDate.replace(/\//g, "-")); // Convert to DD-MM-YYYY format
  };

  const closeModal = () => {
    setIsAddCustomersOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log("Pickup Date:", productionDays);
    const formData = {
      dropOffDate: dropOffDate,
      customer: selectedCustomer?.value,
      laundryItems: orderDetails?.map((detail) => ({
        // type: detail.selectedLaundryType?.value,
        quantity: detail?.quantity,
        price: detail?.costPrice,
        item: detail?.selectedLaundryItem.value,
      })),
      // paymentMethod: paymentMethod,
      ...(paymentMethod && { paymentMethod }),// Only include paymentMethod if it has a value
      discount: discount,
      // pickUpDate: Number(productionDays),
      pickUpDate: Number(productionDays),
      additionalFee: additionalFee,
      logisticFee: logisticFee,
      paymentType: paymentType,
      amountPaid: amountPaid
    };

    try {
      const response = await addNewOrder(formData);

      if (response.msg === "Order created successfully") {
        toast.success(response.msg, {
          autoClose: 2000,
          onClose: () => {
            // Reset form data
            setDropOffDate(""); // Reset drop off date
            setSelectedCustomer(null); // Reset selected customer
            setOrderDetails([
              {
                id: 1,
                selectedLaundryItem: null,
                selectedLaundryType: null,
                quantity: 0,
                costPrice: "",
              },
            ]); // Reset order details
            setPaymentMethod(); // Reset payment method
            setDiscount(""); // Reset discount
            setProductionDays(""); // Reset production days or handle it based on your logic
            setPaymentType("");
            setAmountPaid("");
            setadditionalFee("");
            setLogisticFee("");
          },
        });

        // Delay navigation to after toast close + an additional delay
        const totalDelay = 2000 + 1000; // Toast auto-close time + additional delay
        setTimeout(() => {
          navigate("/dashboard/view-orders");
        }, totalDelay);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error(error.response.msg || "An error occurred");
    }
  };

  // Disable submit button until all required fields are filled
  const isFormValid = () => {
    if (customerType === "existing-customer" && !selectedCustomer) {
      return false;
    }
    if (!dropOffDate || !customerType || !productionDays) {
      return false;
    }
    if (productionDays === "other" && !otherProductionDays) {
      return false;
    }
    if (orderDetails.some(isOrderDetailEmpty)) {
      return false;
    }
    return true;
  };

  return (
    <div className="flex font-mono">
      <div className="flex flex-col flex-1">
        <main className="flex-1 max-h-full p-5 overflow-hidden overflow-y-scroll">
          <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row">
            <h1 className="text-2xl text-primary font-semibold whitespace-nowrap">
              Record Orders
            </h1>
          </div>

          <div className="grid grid-cols-12 gap-5 mt-6">
            <div className="col-span-12 lg:col-span-7">
              <div className="flex flex-col space-y-1 pb-3">
                <div className="border-2 border-primary self-center rounded-full p-2">
                  <Icon className="text-xl text-primary" icon="bxs:user" />
                </div>
                <div className="text-primary text-center">
                  Create A New Order
                </div>
              </div>
              <ToastContainer position="top-right" autoClose={3000} />
              <div className="relative">
                <div className="absolute inset-x-0 top-0 bg-primary h-2 w-full rounded-t-lg"></div>
                <div className="bg-white p-4 transition-shadow border rounded-md shadow-sm hover:shadow-lg">
                  <form className="p-3 font-mono" onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label
                        htmlFor="dropoff"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Drop-off Date
                      </label>
                      <input
                        type="date"
                        id="dropoff"
                        value={dropOffDate.split("-").reverse().join("-")}
                        onChange={handleDropOffDateChange}
                        name="dropOffDate"
                        className="mt-1 p-2 border rounded-md w-full border border-green-300 hover:border-green-500 focus:outline-none"
                        placeholder="Pick the Drop-off date"
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="CustomerType"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Customer Type
                      </label>
                      <select
                        id="CustomerType"
                        name="CustomerType"
                        value={customerType}
                        onChange={handleCustomerTypeChange}
                        required
                        className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                      >
                        <option value="">Select customer type</option>
                        {/* <option value="new-customer">New Customer</option> */}
                        <option value="existing-customer">
                          Existing Customer
                        </option>
                      </select>
                    </div>

                    {customerType === "existing-customer" && (
                      <div className="mb-4">
                        <label
                          htmlFor="existingCustomer"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Name of Customer
                        </label>
                        <Select
                          id="existingCustomer"
                          options={allcustomers?.customers?.map((customer) => ({
                            value: customer._id,
                            label: `${customer?.fullName} (${customer?.phoneNumber})`,
                          }))}
                          value={selectedCustomer}
                          onChange={setSelectedCustomer}
                          placeholder="Select or search for customer"
                          className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                          required
                        />
                      </div>
                    )}

                    {/* {customerType === "new-customer" && (
                      <>
                        <h3 className="uppercase text-sm font-bold mb-2">
                          Add a new customer
                        </h3>
                        <div className="mb-4 flex gap-4">
                          <div className="w-1/2">
                            <label
                              htmlFor="customerName"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Customer Name
                            </label>
                            <div className="flex mt-1">
                              <input
                                type="text"
                                id="customerName"
                                name="vat"
                                className="mt-1 p-2 border rounded-md w-full border border-green-300 hover:border-green-500 focus:outline-none"
                                placeholder="Enter the customer name"
                                required
                              />
                            </div>
                          </div>
                          <div className="w-1/2">
                            <label
                              htmlFor="emailAddress"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Email Address
                            </label>
                            <div className="flex mt-1">
                              <input
                                type="email"
                                id="emailAddress"
                                name="discount"
                                className="mt-1 p-2 border rounded-md w-full border border-green-300 hover:border-green-500 focus:outline-none"
                                placeholder="Enter the email address"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-4 flex gap-4">
                          <div className="w-1/2">
                            <label
                              htmlFor="customerName"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Phone Number
                            </label>
                            <div className="flex mt-1">
                              <input
                                type="number"
                                id="customerName"
                                name="vat"
                                className="mt-1 p-2 border rounded-md w-full border border-green-300 hover:border-green-500 focus:outline-none"
                                placeholder="Enter the phone number"
                                required
                              />
                            </div>
                          </div>
                          <div className="w-1/2">
                            <label
                              htmlFor="emailAddress"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Home Address
                            </label>
                            <div className="flex mt-1">
                              <input
                                type="text"
                                id="emailAddress"
                                name="discount"
                                className="mt-1 p-2 border rounded-md w-full border border-green-300 hover:border-green-500 focus:outline-none"
                                placeholder="Enter the home address"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-4 flex gap-4">
                          <div className="w-1/2">
                            <label
                              htmlFor="customerName"
                              className="block text-sm font-medium text-gray-700"
                            >
                              City
                            </label>
                            <div className="flex mt-1">
                              <input
                                type="number"
                                id="customerName"
                                name="vat"
                                className="mt-1 p-2 border rounded-md w-full border border-green-300 hover:border-green-500 focus:outline-none"
                                placeholder="Enter the city"
                                required
                              />
                            </div>
                          </div>
                          <div className="w-1/2">
                            <label
                              htmlFor="emailAddress"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Local Govt.
                            </label>
                            <div className="flex mt-1">
                              <input
                                type="text"
                                id="emailAddress"
                                name="discount"
                                className="mt-1 p-2 border rounded-md w-full border border-green-300 hover:border-green-500 focus:outline-none"
                                placeholder="Enter the local govt."
                                required
                              />
                            </div>
                          </div>
                        </div>
                       
                      </>
                    )} */}

                    <h3 className="uppercase text-sm font-bold mb-2">
                      Add order details
                    </h3>
                    {orderDetails?.map((detail, index) => (
                      <div key={detail?.id}>
                        <div className="mb-4">
                          <label
                            htmlFor={`laundryItem-${detail?.id}`}
                            className="block text-sm font-medium text-gray-700"
                          >
                            Laundry Item
                          </label>
                          <Select
                            id={`laundryItem-${detail.id}`}
                            options={laundryItems?.map((item) => ({
                              value: item._id,
                              label: item.item,
                            }))}
                            value={detail?.selectedLaundryItem}
                            onChange={(selectedOption) =>
                              handleLaundryItemChange(selectedOption, index)
                            }
                            placeholder="Select or search for laundry item"
                            className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none focus:ring-0 focus:border-transparent"
                            required
                          />
                        </div>
                        {/* {detail.selectedLaundryItem && (
                          <div className="mb-4">
                            <label
                              htmlFor={`laundryType-${detail.id}`}
                              className="block text-sm font-medium text-gray-700"
                            >
                              Laundry Services
                            </label>
                            <Select
                              id={`laundryType-${detail.id}`}
                              options={laundryTypes?.map((item) => ({
                                value: item._id,
                                label: item.type,
                              }))}
                              value={detail.selectedLaundryType}
                              onChange={(selectedOption) => {
                                const updatedOrderDetails = [...orderDetails];
                                updatedOrderDetails[index].selectedLaundryType =
                                  selectedOption;
                                setOrderDetails(updatedOrderDetails);
                              }}
                              placeholder="Select the type of laundry service"
                              className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none focus:ring-0 focus:border-transparent"
                          
                            />
                          </div>
                        )} */}
                        {detail.selectedLaundryItem && (
                          <div className="mb-4 flex gap-4">
                            <div className="w-1/2">
                              <label
                                htmlFor={`quantity-${detail.id}`}
                                className="block text-sm font-medium text-gray-700"
                              >
                                Quantity
                              </label>
                              <div className="flex mt-1">
                                <button
                                  type="button"
                                  onClick={() => handleDecrement(index)}
                                  className="px-3 py-1 text-white bg-primary text-black text-lg rounded-l-md focus:outline-none"
                                >
                                  -
                                </button>
                                <input
                                  type="text"
                                  id={`quantity-${detail.id}`}
                                  name={`quantity-${detail.id}`}
                                  value={detail.quantity}
                                  className="px-3 py-1 border border-green-300 rounded-none w-full text-center border-l-0 border-r-0"
                                  readOnly
                                  required
                                />
                                <button
                                  type="button"
                                  onClick={() => handleIncrement(index)}
                                  className="px-3 py-1 text-white bg-primary text-black text-lg rounded-r-md focus:outline-none"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div className="w-1/2">
                              <label
                                htmlFor={`costPrice-${detail.id}`}
                                className="block text-sm font-medium text-gray-700"
                              >
                                Cost Price
                              </label>
                              <div className="flex mt-1">
                                <button
                                  type="button"
                                  className="px-3 py-1 text-white bg-primary text-black rounded-l-md focus:outline-none"
                                >
                                  &#x20A6;
                                </button>
                                <input
                                  type="number"
                                  id={`costPrice-${detail.id}`}
                                  name={`costPrice-${detail.id}`}
                                  value={detail.costPrice}
                                  onChange={(e) => {
                                    const updatedOrderDetails = [
                                      ...orderDetails,
                                    ];
                                    updatedOrderDetails[index].costPrice =
                                      e.target.value;
                                    setOrderDetails(updatedOrderDetails);
                                  }}
                                  className="px-3 py-1 border rounded-1-md w-full border-green-300 focus:outline-none"
                                  required
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}

                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={handleAddMoreOrder}
                        className={`py-2 px-4 rounded-md text-xs ${
                          isAnyOrderDetailEmpty()
                            ? "bg-gray-400 cursor-not-allowed"
                            : "bg-primary"
                        } text-white mr-2`}
                        disabled={isAnyOrderDetailEmpty()}
                      >
                        +Add More Item
                      </button>
                      {orderDetails.length > 1 && (
                        <button
                          type="button"
                          onClick={handleRemoveLastOrderDetail}
                          className="bg-red-500 text-white py-2 px-4 rounded-md text-xs"
                        >
                          -Remove Item
                        </button>
                      )}
                    </div>

                    <h3 className="uppercase text-sm font-bold mb-2">
                      Add payment method
                    </h3>

                    <div className="mb-4 flex gap-4">
                    <div className={`${paymentType === "full payment" ||paymentType === "no payment" || paymentType === "" ? "w-full" : "w-1/2"}`}>
                        <label
                          htmlFor="paymentType"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Payment Plan
                        </label>
                        <select
                          id="paymentType"
                          name="paymentType"
                          value={paymentType}
                          onChange={(e) => setPaymentType(e.target.value)}
                          required
                          className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                        >
                          <option value="">Select payment plan</option>
                          <option value="no payment">No payment</option>
                          <option value="part payment">Part Payment</option>
                          <option value="full payment">Full Payment</option>
                        </select>
                      </div>

                      {(paymentType === "part payment" ) && (
                        <div className="w-1/2">
                          <label
                            htmlFor="amountpaid"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Amount Paid
                          </label>
                          <div className="flex mt-1">
                            <button
                              type="button"
                              className="px-3 text-white py-1 bg-primary text-black rounded-l-md focus:outline-none"
                            >
                              &#x20A6;
                            </button>
                            <input
                              type="number"
                              id="amountpaid"
                              name="amountpaid"
                              value={amountPaid}
                              onChange={(e) => setAmountPaid(e.target.value)}
                              className="px-3 py-1 border rounded-r-md w-full border-green-300 focus:outline-none"
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mb-4 flex gap-4">
  {(paymentType === "part payment" || paymentType === "full payment") && (
    <div className="w-1/2">
      <label
        htmlFor="paymentMethod"
        className="block text-sm font-medium text-gray-700"
      >
        Payment Method
      </label>
      <select
        id="paymentMethod"
        name="paymentMethod"
        value={paymentMethod}
        onChange={(e) => setPaymentMethod(e.target.value)}
        className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
      >
        <option value="">Select payment method</option>
        <option value="Cash">Cash</option>
        <option value="Transfer">Transfer</option>
      </select>
    </div>
  )}

  <div className={`${paymentType === "no payment" ? "w-full" : "w-1/2"}`}>
    <label
      htmlFor="discount"
      className="block text-sm font-medium text-gray-700"
    >
      Discount % (If Applicable)
    </label>
    <div className="flex mt-1">
      <button
        type="button"
        className="px-3 text-white py-1 bg-primary text-black rounded-l-md focus:outline-none"
      >
        &#37;
      </button>
      <input
        type="number"
        id="discount"
        name="discount"
        value={discount}
        onChange={(e) => setDiscount(e.target.value)}
        className="px-3 py-1 border rounded-1-md w-full border-green-300 focus:outline-none"
      />
    </div>
  </div>
</div>

                    <div className="mb-4 flex gap-4">
                      <div className="w-1/2">
                        <label
                          htmlFor="additionalFee"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Additional Fee (If Applicable)
                        </label>
                        <div className="flex mt-1">
                          <button
                            type="button"
                            className="px-3 text-white py-1 bg-primary text-black rounded-l-md focus:outline-none"
                          >
                            &#x20A6;
                          </button>
                          <input
                            type="number"
                            id="additionalFee"
                            name="additionalFee"
                            value={additionalFee}
                            onChange={(e) => setadditionalFee(e.target.value)}
                            className="px-3 py-1 border rounded-1-md w-full border-green-300 focus:outline-none"
                          />
                        </div>
                      </div>

                      <div className="w-1/2">
                        <label
                          htmlFor="logisticFee"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Logistics Fee (If Applicable)
                        </label>
                        <div className="flex mt-1">
                          <button
                            type="button"
                            className="px-3 text-white py-1 bg-primary text-black rounded-l-md focus:outline-none"
                          >
                            &#x20A6;
                          </button>
                          <input
                            type="number"
                            id="logisticFee"
                            name="logisticFee"
                            value={logisticFee}
                            onChange={(e) => setLogisticFee(e.target.value)}
                            className="px-3 py-1 border rounded-1-md w-full border-green-300 focus:outline-none"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="flex gap-4 mb-4">
                        <div className="w-1/2">
                          <label
                            htmlFor="production"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Days of Production
                          </label>
                          <div className="flex mt-1">
                            <select
                              id="production"
                              name="pickUpDate"
                              value={productionDays}
                              onChange={handleProductionChange}
                              required
                              className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                            >
                              <option value="">Select production days</option>
                              <option value="0">Same day</option>
                              <option value="1">1 day</option>
                              <option value="2">2 days</option>
                              <option value="3">3 days</option>
                              <option value="4">4 days</option>
                              <option value="5">5 days</option>
                              <option value="6">6 days</option>
                              <option value="7">7 days</option>
                              <option value="other">
                                Other (Please specify)
                              </option>
                            </select>
                          </div>
                          {productionDays === "other" && (
                            <div className="mt-2">
                              <input
                                type="text"
                                id="otherProductionDays"
                                name="otherProductionDays"
                                value={otherProductionDays}
                                onChange={handleOtherProductionChange}
                                placeholder="Please specify the number of days"
                                className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                                required
                              />
                            </div>
                          )}
                        </div>
                        <div className="w-1/2">
                          <label
                            htmlFor="pickupDate"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Expected Pickup Date
                          </label>
                          <div className="flex mt-1">
                            <input
                              type="text"
                              id="pickupDate"
                              name="pickUpDate"
                              value={pickupDate}
                              placeholder="Pickup Date"
                              readOnly
                              className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      disabled={!isFormValid() || loading} // Disable button if form is not valid
                      className={`bg-primary text-white py-2 px-4 w-full rounded-md ${
                        !isFormValid() || loading
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:bg-primary-dark"
                      }`}
                    >
                      {loading ? "Loading..." : "Add New Order"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-5">
              <div className="flex flex-col space-y-1 pb-3">
                <div className="border-2 border-primary self-center rounded-full p-2">
                  <Icon
                    className="text-xl text-primary"
                    icon="mingcute:shopping-bag-1-fill"
                  />
                </div>
                <div className="text-primary text-center">Summary Order</div>
              </div>
              <PreviewInvoice
                orderDetails={orderDetails}
                pickupDate={pickupDate}
                customerType={customerType}
                selectedCustomer={selectedCustomer}
                paymentMethod={paymentMethod}
                vat={vat}
                discount={discount}
                dropOffDate={dropOffDate}
                amountPaid={amountPaid}
                paymentType={paymentType}
                additionalFee={additionalFee}
                logisticFee={logisticFee}
              />
              {/* Receipt Modal */}
              <GenerateReceipts
                open={showReceiptModal}
                closeModal={closeModal}
                orderDetails={orderDetails}
                // orderId={orderId}
                pickupDate={pickupDate}
                dropOffDate={dropOffDate}
                customerType={customerType}
                selectedCustomer={selectedCustomer}
                paymentMethod={paymentMethod}
                discount={discount}
                vat={vat}
                amountPaid={amountPaid}
                paymentType={paymentType}
                logisticFee={logisticFee}
                additionalFee={additionalFee}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default CreateOrders;
