import React, { useState, useEffect } from "react";
import TanTable from "../../utils/TanTable";
import { useAuth } from "../../contextAPI/AuthContext";
import Papa from "papaparse";
import { saveAs } from "file-saver";

export default function OutstandingBalanceListTable({
  reloadCustomers,
  setReloadCustomers,
}) {
  const { authState,fetchAllBalance } = useAuth();
  const [filterPaymentType, setPaymentType] = useState("");
  const {  user,allBalance } = authState;
  const [filterNameOrPhone, setFilterNameOrPhone] = useState("");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
  
      fetchAllBalance(token)
    }
  }, [reloadCustomers, fetchAllBalance]);

  let sortedCustomers = [];
  if (allBalance && allBalance.orders) {
    sortedCustomers = allBalance.orders.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
  }
  
  const filteredCustomers = sortedCustomers.filter((order) => {
    const customerName = order?.customer?.fullName?.toLowerCase() || "";
    const customerPhone = order?.customer?.phoneNumber || "";
    const customerEmail = order?.customer?.email || "";
  
    const matchesNameOrPhoneOrDate =
      !filterNameOrPhone ||
      customerName.includes(filterNameOrPhone.toLowerCase()) ||
      customerPhone.includes(filterNameOrPhone) ||
      customerEmail.includes(filterNameOrPhone);
  
    const matchesPaymentType = filterPaymentType
      ? order.paymentType.toLowerCase() === filterPaymentType.toLowerCase()
      : true;
  
    // Combine both conditions
    return matchesNameOrPhoneOrDate && matchesPaymentType;
  });
  
  const downloadCSV = () => {
    const csvData = filteredCustomers.map((customer, index) => ({
      "S/N": index + 1,
      Date: new Date(customer.updatedAt).toLocaleDateString(),
      "Customer Name": customer?.customer?.fullName,
      "Phone number": customer?.customer?.phoneNumber,
      "Email": customer?.customer?.email,
      "Total Amount": customer?.totalAmount,
      "Payment Type": customer?.paymentType,
      "Additional Fee": customer?.additionalFee,
      "Logistics Fee": customer?.logisticFee,
      "Amount Paid": customer?.amountPaid,

    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Swifttouchdebtors.csv");
  };

  const columns = [
    {
      id: "S/N",
      header: "S/N",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      id: "Date",
      header: "Date",
      cell: (item) => {
        const currentItem = item.row.original;
        const formattedDate = new Date(
          currentItem.updatedAt
        ).toLocaleDateString();
        return <span className="capitalize">{formattedDate}</span>;
      },
    },
    {
      id: "Customer Name",
      header: "Customer Name",
      cell: (item) => {
        const currentItem = item.row.original;
        return <span className="capitalize">{currentItem?.customer?.fullName}</span>;
      },
    },
    {
      id: "Phone number",
      header: "Phone number",
      cell: (item) => {
        const currentItem = item.row.original;
        return <span className="capitalize">{currentItem?.customer?.phoneNumber}</span>;
      },
    },
    // {
    //   id: "Email",
    //   header: "Email",
    //   cell: (item) => {
    //     const currentItem = item.row.original;
    //     return <span className="">{currentItem?.customer?.email}</span>;
    //   },
    // },
    {
      id: "Laundry Items",
      header: "Laundry Items",
      cell: (item) => {
        const currentItem = item.row.original;
        const truncateText = (text, maxLength) => {
          if (!text) return "";
          return text.length > maxLength
            ? `${text.substring(0, maxLength)}...`
            : text;
        };

        return (
          <ul>
            {currentItem?.laundryItems?.map((laundryItem, index) => (
              <li key={index}>
                {truncateText(`${laundryItem?.item?.item}`, 20)}
                {/* {laundryItem?.item?.item} - {laundryItem?.type?.type} */}
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      id: "Total Amount",
      header: "Total Amount",
      cell: (item) => {
        const currentItem = item.row.original;
        const formattedTotal = currentItem?.totalAmount.toLocaleString(
          "en-NG",
          {
            style: "currency",
            currency: "NGN",
          }
        );
        return <span className="">{formattedTotal}</span>;
      },
    },
    {
      id: "Payment Status",
      header: "Payment Status",
      cell: (item) => {
        const currentItem = item.row.original;
        const status = currentItem.paymentType;

        const getStatusClass = (status) => {
          switch (status) {
            case "no payment":
              return "bg-red-500 text-white";
            case "part payment":
              return "bg-emerald-800 text-white";
            default:
              return "bg-black text-white";
          }
        };

        return (
          <span
            className={`inline-block w-24 px-2 py-1 rounded text-center capitalize ${getStatusClass(
              status
            )}`}
          >
            {status}
          </span>
        );
      },
    },
    {
      id: "Discount",
      header: "Discount",
      cell: (item) => {
        const currentItem = item.row.original;
       
        return <span className="">
         {currentItem?.discount !== null ? (
                    <div>{currentItem?.discount} %</div>
                  ) : (
                    <div>0 %</div>
                  )}</span>;
      },
    },
   
    {
      id: "Additional Fee",
      header: "Additional Fee",
      cell: (item) => {
        const currentItem = item.row.original;
        const formattedAmountPaid = currentItem?.additionalFee?.toLocaleString(
          "en-NG",
          {
            style: "currency",
            currency: "NGN",
          }
        );
    
        // Format zero amount with currency symbol
        const zeroAmountFormatted = (0).toLocaleString("en-NG", {
          style: "currency",
          currency: "NGN",
        });
    
        return (
          <span className="">
            {formattedAmountPaid ? (
              <div>{formattedAmountPaid}</div>
            ) : (
              <div>{zeroAmountFormatted}</div>
            )}
          </span>
        );
      },
    },
    {
      id: "Logistics Fee",
      header: "Logistics Fee",
      cell: (item) => {
        const currentItem = item.row.original;
        const formattedAmountPaid = currentItem?.logisticFee?.toLocaleString(
          "en-NG",
          {
            style: "currency",
            currency: "NGN",
          }
        );
    
        // Format zero amount with currency symbol
        const zeroAmountFormatted = (0).toLocaleString("en-NG", {
          style: "currency",
          currency: "NGN",
        });
    
        return (
          <span className="">
            {formattedAmountPaid ? (
              <div>{formattedAmountPaid}</div>
            ) : (
              <div>{zeroAmountFormatted}</div>
            )}
          </span>
        );
      },
    },
    {
      id: "Amount Paid",
      header: "Amount Paid",
      cell: (item) => {
        const currentItem = item.row.original;
        const formattedAmountPaid = currentItem?.amountPaid?.toLocaleString(
          "en-NG",
          {
            style: "currency",
            currency: "NGN",
          }
        );
    
        // Format zero amount with currency symbol
        const zeroAmountFormatted = (0).toLocaleString("en-NG", {
          style: "currency",
          currency: "NGN",
        });
    
        return (
          <span className="">
            {formattedAmountPaid ? (
              <div>{formattedAmountPaid}</div>
            ) : (
              <div>{zeroAmountFormatted}</div>
            )}
          </span>
        );
      },
    },
    {
      id: "Balance",
      header: "Balance",
      cell: (item) => {
        const currentItem = item.row.original;
     
        const totalAmount = currentItem?.totalAmount;
        const amountPaidByClient = currentItem?.amountPaid;
        const OutstandingBalance =
          Number(totalAmount) - Number(amountPaidByClient || 0);
          const formattedAmountPaid = OutstandingBalance?.toLocaleString(
            "en-NG",
            {
              style: "currency",
              currency: "NGN",
            }
          );
        // Format zero amount with currency symbol
        const zeroAmountFormatted = (0).toLocaleString("en-NG", {
          style: "currency",
          currency: "NGN",
        });
    
        return (
          <span className="capitalize inline-block w-24 px-2 py-1 rounded text-center text-white bg-green-500">
            {formattedAmountPaid ? (
              <div>{formattedAmountPaid}</div>
            ) : (
              <div>{zeroAmountFormatted}</div>
            )}
          </span>
        );
      },
    }
    
  ];

  if (!allBalance || !allBalance?.orders) {
    return <div className="text-center py-4 font-bold text-lg">Loading...</div>;
  }

  return (
    <>
      <div className="filter-container p-4 flex justify-between">
        {/* Left side: Search input */}
        <input
          type="text"
          value={filterNameOrPhone}
          onChange={(e) => setFilterNameOrPhone(e.target.value)}
          placeholder="Search..."
          className="rounded-md border-2 border-primary p-2 focus:border-primary
                focus:outline-none focus:ring-1"
        />

        {/* Right side: Filter select and Download CSV button */}
        <div className="flex items-center space-x-4">
          {/* Download CSV button */}
          {user?.role === "admin" && (
            <div
              className="cursor-pointer inline-flex items-center justify-center text-primary
        px-4 py-2 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20"
              onClick={downloadCSV}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    stroke-width="2"
                    fill="transparent"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6v6m0 0v6m0-6l-4-4m4 4l4-4"
                  />
                </svg>
              </span>
              <span>Download CSV</span>
            </div>
          )}
        </div>

            {/* Right side: Filter select and checkboxes */}
            <div className="flex items-center space-x-4">
          {/* Filter select */}
          
          <select
            value={filterPaymentType}
            onChange={(e) => setPaymentType(e.target.value)}
            className="rounded-md border-2 border-primary p-2 focus:border-primary
                focus:outline-none focus:ring-1"
          >
            <option value="">Filter by Payment Type</option>
            <option value="no payment">No Payment</option>
            <option value="part payment">Part Payment</option>
          </select>
         
        </div>

      </div>
      <TanTable columnData={columns} data={filteredCustomers} />
      
    </>
  );
}
