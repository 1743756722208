import React, { useState, useEffect } from "react";
import TanTable from "../../utils/TanTable";
import { useAuth } from "../../contextAPI/AuthContext";
import EditOrdersModal from "../Modals/EditOrdersModal";
import ViewOrdersModal from "../Modals/ViewOrdersModal";
import DeleteOrdersModal from "../Modals/DeleteOrdersModal";
import SendImage from "../../assets/logo/send-icon.jpeg";
import GenerateReceipts from "../Modals/GenerateReceipt";
import Papa from "papaparse";
import { saveAs } from "file-saver";

export default function ViewAllOrdersTable() {
  const { authState, fetchAllOrders } = useAuth();
  const { allOrders, user } = authState;
  const [isAddCustomersOpen, setIsAddCustomersOpen] = useState(false);
  const [isViewOrdersOpen, setIsViewOrdersOpen] = useState(false);
  const [isDeleteCustomersOpen, setIsDeleteCustomersOpen] = useState(false);
  const [isOpenReceipts, setIsOpenReceipts] = useState(false);
  const [selectedOrderId, setselectedOrderId] = useState(null);
  const [reloadCustomers, setReloadCustomers] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [filterDate, setFilterDate] = useState("");
  const [filterNameOrPhone, setFilterNameOrPhone] = useState("");
  // const [filterPhone, setFilterPhone] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterPaymentType, setPaymentType] = useState("");
  // const [filterType, setFilterType] = useState("all");
  const [filterDaily, setFilterDaily] = useState(false);
  const [filterMonthly, setFilterMonthly] = useState(false);
  // const [filterOrderId, setFilterOrderId] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchAllOrders(token);
    }
  }, [fetchAllOrders, reloadCustomers]);

  let sortedOrders = [];
  if (allOrders && allOrders.orders) {
    sortedOrders = allOrders.orders.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
  }
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-"); // Split by "-"
    return new Date(`${year}-${month}-${day}`);
  };

  const generateOrderID = (orders, dropOffDate, _id) => {
    const targetDate = parseDate(dropOffDate); // Use parseDate to get the targetDate
    const shortenedOrderId = _id?.substring(4, 7); // Extract from index 3 to index 6
    const day = targetDate.getDate().toString().padStart(2, "0"); // Get day as two digits
    const month = targetDate
      .toLocaleString("en-US", { month: "short" })
      .toUpperCase(); // Get abbreviated month in uppercase
    const year = targetDate.getFullYear(); // Get full year
    const generatedOrderId=  `SL${shortenedOrderId}/${day}${month}/${year}`;
  

    // Save generatedOrderId in local storage
    localStorage.setItem(`generatedOrderId_${_id}`, generatedOrderId);

    return generatedOrderId;
  };

  const filteredOrders = sortedOrders
    .filter((order) => {
      const customerName = order?.customer?.fullName?.toLowerCase() || "";
      const customerPhone = order?.customer?.phoneNumber || "";
      const customerDropoffDate = order?.dropOffDate || "";
      const orderId = order?._id;

      const targetDate = parseDate(customerDropoffDate); // Use parseDate to get the targetDate
      const shortenedOrderId = orderId?.substring(4, 7); // Extract from index 3 to index 6
      const day = targetDate.getDate().toString().padStart(2, "0"); // Get day as two digits
      const month = targetDate
        .toLocaleString("en-US", { month: "short" })
        .toUpperCase(); // Get abbreviated month in uppercase
      const year = targetDate.getFullYear(); // Get full year
      const generatedOrder=  `SL${shortenedOrderId}/${day}${month}/${year}`;

      const matchesNameOrPhoneOrDate =
        !filterNameOrPhone ||
        customerName.includes(filterNameOrPhone.toLowerCase()) ||
        customerPhone.includes(filterNameOrPhone) ||
        customerDropoffDate.includes(filterNameOrPhone);
        generatedOrder.includes(filterNameOrPhone);

      const matchesStatus = filterStatus
        ? filterStatus === "all-orders" || order.status === filterStatus
        : true;

        const matchesPaymentType = filterPaymentType
  ? order.paymentType.toLowerCase() === filterPaymentType.toLowerCase()
  : true;
      const orderDate = parseDate(order.dropOffDate);
      const today = new Date();
      const isSameDay =
        orderDate.getDate() === today.getDate() &&
        orderDate.getMonth() === today.getMonth() &&
        orderDate.getFullYear() === today.getFullYear();

      const isSameMonth =
        orderDate.getMonth() === today.getMonth() &&
        orderDate.getFullYear() === today.getFullYear();

      const matchesDailyFilter = filterDaily ? isSameDay : true;
      const matchesMonthlyFilter = filterMonthly ? isSameMonth : true;

      return (
        matchesNameOrPhoneOrDate &&
        matchesStatus &&
        matchesDailyFilter &&
        matchesMonthlyFilter && 
        matchesPaymentType
      );
    })
    .map((order) => ({
      ...order,
      generatedOrderId: generateOrderID(
        sortedOrders,
        order.dropOffDate,
        order._id
      ),
      
    }));

  const openDeleteCustomers = (id) => {
    setselectedOrderId(id);
    setIsDeleteCustomersOpen(true);
  };
  const openReceipts = (id) => {
    setselectedOrderId(id);
    setIsOpenReceipts(true);
  };
  const closeReceipts = () => {
    setIsOpenReceipts(false);
    setselectedOrderId(null);
    setReloadCustomers((prev) => !prev);
  };

  const closeDeleteModal = () => {
    setIsDeleteCustomersOpen(false);
    setselectedOrderId(null);
    setReloadCustomers((prev) => !prev);
  };

  const closeModal = () => {
    setIsAddCustomersOpen(false);
    setselectedOrderId(null);
    setReloadCustomers((prev) => !prev);
  };
  const openEditModal = (id) => {
    setselectedOrderId(id);
    setIsAddCustomersOpen(true);
  };
  const openViewModal = (id) => {
    setselectedOrderId(id);
    setIsViewOrdersOpen(true);
  };
  const closeViewModal = () => {
    setIsViewOrdersOpen(false);
    setselectedOrderId(null);
    setReloadCustomers((prev) => !prev);
  };

  const handleMenuClick = (event, id) => {
    setselectedOrderId(id);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString();
  };

  const getTotalQuantity = (laundryItems) => {
    return laundryItems.reduce((total, item) => total + item.quantity, 0);
  };

  const downloadCSV = () => {
    const csvData = filteredOrders.map((order, index) => ({
      "S/N": index + 1,
      "Dropoff Date": order.dropOffDate,
      "Customer Name": order.customer.fullName,
      "Phone number": order.customer.phoneNumber,
      "Laundry Items": order.laundryItems
        .map((item) => `${item.item.item}`)
        .join(", "),
      Qty: getTotalQuantity(order.laundryItems),
      Total: order.totalAmount.toLocaleString("en-NG", {
        style: "currency",
        currency: "NGN",
      }),
      Status: order.status,
      "Ready Date": formatDate(order.readyDate),
    }));
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Swifttouchorders.csv");
  };

  const columns = [
    {
      id: "S/N",
      header: "S/N",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      id: "Order ID",
      header: "Order ID",
      cell: (item) => {
        const currentItem = item.row.original;
        return (
          <span className="font-bold uppercase">
            {currentItem.generatedOrderId}
          </span>
        );
      },
    },
    {
      id: "Dropoff Date",
      header: "Dropoff Date",
      cell: (item) => {
        const currentItem = item.row.original;
        return <span className="capitalize">{currentItem?.dropOffDate}</span>;
      },
    },
    {
      id: "Customer Name",
      header: "Customer Name",
      cell: (item) => {
        const currentItem = item.row.original;
        return (
          <span className="capitalize">{currentItem?.customer?.fullName}</span>
        );
      },
    },
    {
      id: "Phone number",
      header: "Phone number",
      cell: (item) => {
        const currentItem = item.row.original;
        return (
          <span className="capitalize">
            {currentItem?.customer?.phoneNumber}
          </span>
        );
      },
    },

    {
      id: "Laundry Items",
      header: "Laundry Items",
      cell: (item) => {
        const currentItem = item.row.original;
        const truncateText = (text, maxLength) => {
          if (!text) return "";
          return text.length > maxLength
            ? `${text.substring(0, maxLength)}...`
            : text;
        };

        return (
          <ul>
            {currentItem?.laundryItems?.map((laundryItem, index) => (
              <li key={index}>
                {truncateText(`${laundryItem?.item?.item}`, 20)}
                {/* {laundryItem?.item?.item} - {laundryItem?.type?.type} */}
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      id: "Qty",
      header: "Qty",
      cell: (item) => {
        const currentItem = item.row.original;
        const totalQuantity = getTotalQuantity(currentItem.laundryItems);
        return <span>{totalQuantity} pcs</span>;
      },
    },
    {
      id: "Total",
      header: "Total",
      cell: (item) => {
        const currentItem = item.row.original;
        const formattedTotal = currentItem?.totalAmount.toLocaleString(
          "en-NG",
          {
            style: "currency",
            currency: "NGN",
          }
        );
        return <span className="">{formattedTotal}</span>;
      },
    },
    {
      id: "Status",
      header: "Status",
      cell: (item) => {
        const currentItem = item.row.original;
        const status = currentItem.status;

        const getStatusClass = (status) => {
          switch (status) {
            case "pending":
              return "bg-orange-500 text-white";
            case "in-progress":
              return "bg-yellow-500 text-white";
            case "completed":
              return "bg-green-500 text-white";
            default:
              return "bg-transparent text-black";
          }
        };

        return (
          <span
            className={`inline-block w-24 px-2 py-1 rounded text-center capitalize ${getStatusClass(
              status
            )}`}
          >
            {status}
          </span>
        );
      },
    },
    {
      id: "Ready Date",
      header: "Ready Date",
      cell: (item) => {
        const currentItem = item.row.original;
        const formattedDate = formatDate(currentItem.readyDate);
        return <span className="">{formattedDate}</span>;
      },
    },
    {
      id: "Receipts",
      header: "Receipts",
      cell: (item) => {
        const id = item.row.original._id;
        return (
          <>
            <button onClick={() => openReceipts(id)}>
              <img src={SendImage} alt="logo" className="w-6" />
              {/* Unicode character for horizontal ellipsis (3 dots) */}
            </button>
          </>
        );
      },
    },
    {
      id: "Payment Status",
      header: "Payment Status",
      cell: (item) => {
        const currentItem = item.row.original;
        const status = currentItem.paymentType;

        const getStatusClass = (status) => {
          switch (status) {
            case "no payment":
              return "bg-red-500 text-white";
            case "part payment":
              return "bg-emerald-800 text-white";
            case "full payment":
              return "bg-cyan-800 text-white";
            default:
              return "bg-black text-white";
          }
        };

        return (
          <span
            className={`inline-block w-24 px-2 py-1 rounded text-center capitalize ${getStatusClass(
              status
            )}`}
          >
            {status}
          </span>
        );
      },
    },
    {
      id: "Actions",
      header: "Actions",
      cell: (item) => {
        const id = item.row.original._id;
        return (
          <>
            <button
              className="dropdown-button"
              onClick={(event) => handleMenuClick(event, id)}
            >
              &#x2026;{" "}
              {/* Unicode character for horizontal ellipsis (3 dots) */}
            </button>
            {anchorEl && selectedOrderId === id && (
              <div className="dropdown-menu">
                <button
                  onClick={() => openViewModal(id)}
                  className="flex items-center"
                >
                  <svg
                    className="w-4 h-4 text-primary cursor-pointer"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 4C7.582 4 4 7.582 4 12C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12C20 7.582 16.418 4 12 4ZM2 12C2 6.486 6.486 2 12 2C17.514 2 22 6.486 22 12C22 13.983 21.33 15.829 20.198 17.322C19.605 18.112 18.757 18.757 17.878 19.253C17.827 19.278 17.774 19.302 17.719 19.322C16.581 19.764 14.854 20.578 12 20.578C7.666 20.578 3.922 17.797 2.247 14.122C2.089 13.722 2 13.36 2 13V12ZM11.5 16C11.5 16.8284 10.8284 17.5 10 17.5C9.17157 17.5 8.5 16.8284 8.5 16C8.5 15.1716 9.17157 14.5 10 14.5C10.8284 14.5 11.5 15.1716 11.5 16ZM15.5 12C15.5 13.933 14.748 15.728 13 16.75C13.3076 16.8547 13.6299 16.9276 13.9638 16.9671C14.2977 17.0067 14.639 17.0121 14.98 16.983C16.728 15.96 17.5 14.165 17.5 12C17.5 11.1716 16.8284 10.5 16 10.5C15.1716 10.5 14.5 11.1716 14.5 12H15.5Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span className="text-primary cursor-pointer">View</span>
                </button>
                <button
                  onClick={() => openEditModal(id)}
                  className="flex items-center"
                >
                  <svg
                    className="w-4 h-4 text-primary cursor-pointer"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM20.71 7.04C21.1 6.65 21.1 6 20.71 5.61L18.39 3.29C18 2.9 17.35 2.9 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span className="text-primary cursor-pointer">Edit</span>
                </button>
                {user?.role === "admin" && (
                  <button
                    onClick={() => openDeleteCustomers(id)}
                    className="flex items-center"
                  >
                    <svg
                      className="w-4 h-4 text-primary cursor-pointer"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 6H5H21"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 11V17"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14 11V17"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="text-primary cursor-pointer">Delete</span>
                  </button>
                )}
              </div>
            )}
          </>
        );
      },
    },
  ];

  if (!allOrders || !allOrders.orders) {
    return <div className="text-center py-4 font-bold text-lg">Loading...</div>;
  }

  return (
    <>
      <div className="filter-container p-4 flex justify-between items-center">
        {/* Left side: Search input and Download CSV button */}

        <div className="flex items-center space-x-4">
          <input
            type="text"
            value={filterNameOrPhone}
            onChange={(e) => setFilterNameOrPhone(e.target.value)}
            placeholder="Search..."
             className="rounded-md border-2 border-primary p-2 focus:border-primary
                focus:outline-none focus:ring-1"
          />

          {user?.role === "admin" && (
            <div
              className="cursor-pointer inline-flex items-center justify-center text-primary
      px-4 py-2 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20"
              onClick={downloadCSV}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    stroke-width="2"
                    fill="transparent"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6v6m0 0v6m0-6l-4-4m4 4l4-4"
                  />
                </svg>
              </span>
              <span>Download CSV</span>
            </div>
          )}
        </div>
       
        {/* Right side: Filter select and checkboxes */}
        <div className="flex items-center space-x-4">
          {/* Filter select */}
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="rounded-md border-2 border-primary p-2 focus:border-primary
                focus:outline-none focus:ring-1"
          >
            <option value="">Filter by Status</option>
            <option value="all-orders">All Orders</option>
            <option value="pending">Pending</option>
            <option value="in-progress">In Progress</option>
            <option value="completed">Completed</option>
          </select>
          <select
            value={filterPaymentType}
            onChange={(e) => setPaymentType(e.target.value)}
            className="rounded-md border-2 border-primary p-2 focus:border-primary
                focus:outline-none focus:ring-1"
          >
            <option value="">Filter by Payment Type</option>
            <option value="no payment">No Payment</option>
            <option value="part payment">Part Payment</option>
            <option value="full payment">Full Payment</option>
          </select>
          {/* Daily and Monthly Filter checkboxes */}
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={filterDaily}
              onChange={() => setFilterDaily(!filterDaily)}
            />
            <span className="ml-2">Daily</span>
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={filterMonthly}
              onChange={() => setFilterMonthly(!filterMonthly)}
            />
            <span className="ml-2">Monthly</span>
          </label>
        </div>

        
      </div>

      <TanTable columnData={columns} data={filteredOrders} />
      <EditOrdersModal
        open={isAddCustomersOpen}
        closeModal={closeModal}
        orderId={selectedOrderId}
      />
      <ViewOrdersModal
        open={isViewOrdersOpen}
        closeModal={closeViewModal}
        orderId={selectedOrderId}
      />
      <DeleteOrdersModal
        open={isDeleteCustomersOpen}
        closeModal={closeDeleteModal}
        orderId={selectedOrderId}
      />
      <GenerateReceipts
        open={isOpenReceipts}
        closeModal={closeReceipts}
        orderId={selectedOrderId}
      />
    </>
  );
}
