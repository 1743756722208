import React, {useEffect} from "react";
import SidebarMenuLink from "./SidebarMenuLink";
import Swal from "sweetalert2";
import Logo from "../../assets/logo/swifttouch-logo.png";
import { useAuth } from "../../contextAPI/AuthContext";

function Sidebar({ isSidebarOpen, toggleSidebar }) {
  const { authState, fetchUserProfile } = useAuth();
  const { user } = authState;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchUserProfile(token);
    }
  }, [fetchUserProfile]);

  const handleLogout = () => {
    setTimeout(() => {
      Swal.fire({
        title: "Are you sure?",
        text: "You will be logged out!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, log me out!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Perform logout action
          localStorage.removeItem("token");
          // Reload the page or navigate to the login page after logout
          window.location.href = "/";
        }
      });
    }, 1000);
  };

  // Define sidebar links
  const adminLinks = [
    <SidebarMenuLink
      key="dashboard"
      to="/dashboard"
      icon={
        <svg
          className="w-6 h-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          />
        </svg>
      }
      text="Dashboard"
      isSidebarOpen={isSidebarOpen}
    />,
    <SidebarMenuLink
      key="create-order"
      to="/dashboard/create-order"
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 text-white"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <line x1="12" y1="8" x2="12" y2="16" />
          <line x1="8" y1="12" x2="16" y2="12" />
        </svg>
      }
      text="Record Orders"
      isSidebarOpen={isSidebarOpen}
    />,
    <SidebarMenuLink
      key="view-orders"
      to="/dashboard/view-orders"
      icon={
        <svg
          className="w-6 h-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <rect width="18" height="18" x="3" y="3" rx="2" ry="2"></rect>
          <rect width="18" height="18" x="8" y="8" rx="2" ry="2"></rect>
          <rect width="18" height="18" x="13" y="13" rx="2" ry="2"></rect>
        </svg>
      }
      text="View Orders"
      isSidebarOpen={isSidebarOpen}
    />,
     <SidebarMenuLink
      key="customers"
      to="/dashboard/customers"
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 text-white"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M10 1L12.18 6.42L18 7.11L13.82 11.18L15.21 17L10 14.66L4.79 17L6.18 11.18L2 7.11L7.82 6.42L10 1z" />
        </svg>
      }
      text="Customers"
      isSidebarOpen={isSidebarOpen}
    />,
    <SidebarMenuLink
    key="view-staff"
    to="/dashboard/view-staff"
    icon={
      <svg
      className="w-6 h-6 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
      />
    </svg>
    }
    text="Employee"
    isSidebarOpen={isSidebarOpen}
  />,
  <SidebarMenuLink
  key="add-categories"
  to="/dashboard/add-categories"
  icon={
    <svg
    className="w-6 h-6 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <rect x="3" y="3" width="7" height="7" rx="1" ry="1" />
    <rect x="14" y="3" width="7" height="7" rx="1" ry="1" />
    <rect x="3" y="14" width="7" height="7" rx="1" ry="1" />
    <rect x="14" y="14" width="7" height="7" rx="1" ry="1" />
  </svg>
  }
  text="Categories"
  isSidebarOpen={isSidebarOpen}
/>,
  <SidebarMenuLink
  key="outstanding-balance"
  to="/dashboard/outstanding-balance"
  icon={
    <svg
      className="w-6 h-6 text-white"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 1v22m-5-6a5 5 0 0 0 5 5v-4a5 5 0 0 0 0-10v-4a5 5 0 0 0-5 5zm0-10a5 5 0 0 0-5 5h10a5 5 0 0 0-5-5z"
      />
    </svg>
  }
  text="Balance"
  isSidebarOpen={isSidebarOpen}
/>,

  ];

  const userLinks = [
    <SidebarMenuLink
    key="dashboard"
    to="/dashboard"
    icon={
      <svg
        className="w-6 h-6 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
        />
      </svg>
    }
    text="Dashboard"
    isSidebarOpen={isSidebarOpen}
  />,
  <SidebarMenuLink
    key="create-order"
    to="/dashboard/create-order"
    icon={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-6 h-6 text-white"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10" />
        <line x1="12" y1="8" x2="12" y2="16" />
        <line x1="8" y1="12" x2="16" y2="12" />
      </svg>
    }
    text="Record Orders"
    isSidebarOpen={isSidebarOpen}
  />,
  <SidebarMenuLink
    key="view-orders"
    to="/dashboard/view-orders"
    icon={
      <svg
        className="w-6 h-6text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <rect width="18" height="18" x="3" y="3" rx="2" ry="2"></rect>
        <rect width="18" height="18" x="8" y="8" rx="2" ry="2"></rect>
        <rect width="18" height="18" x="13" y="13" rx="2" ry="2"></rect>
      </svg>
    }
    text="View Orders"
    isSidebarOpen={isSidebarOpen}
  />,
 
    <SidebarMenuLink
      key="customers"
      to="/dashboard/customers"
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 text-white"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M10 1L12.18 6.42L18 7.11L13.82 11.18L15.21 17L10 14.66L4.79 17L6.18 11.18L2 7.11L7.82 6.42L10 1z" />
        </svg>
      }
      text="Customers"
      isSidebarOpen={isSidebarOpen}
    />,
    <SidebarMenuLink
    key="outstanding-balance"
    to="/dashboard/outstanding-balance"
    icon={
      <svg
      className="w-6 h-6 text-white"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 1v22m-5-6a5 5 0 0 0 5 5v-4a5 5 0 0 0 0-10v-4a5 5 0 0 0-5 5zm0-10a5 5 0 0 0-5 5h10a5 5 0 0 0-5-5z"
      />
    </svg>
    }
    text="Balance"
    isSidebarOpen={isSidebarOpen}
  />,
  ];

  // Combine all links
  // const allLinks = [...adminLinks, ...userLinks];

  // Determine which links to show based on user role
  const linksToShow = user?.role === 'admin' ? adminLinks : userLinks;


  // const linksToShow = loading ? (
  //   // Display loading message while sidebar is loading
  //   <div className="text-bold">Loading...</div>
  // ) : (
  //   // Show appropriate links based on user role
  //   user?.role === "admin" ? adminLinks : userLinks
  // );
  // Sidebar content
  const sidebarContent = (
    <div>
      {isSidebarOpen && (
        <div className="fixed inset-0 z-10 bg-black bg-opacity-20 lg:hidden"></div>
      )}

      <aside
        className={`fixed inset-y-0 z-10 flex flex-col flex-shrink-0 w-64 max-h-screen overflow-hidden transition-all transform bg-primary h-screen border-r shadow-lg lg:z-auto lg:static lg:shadow-none lg:w-48 ${
          isSidebarOpen
            ? "translate-x-0 lg:w-64"
            : "-translate-x-full lg:translate-x-0 lg:w-20"
        }`}
      >
        <div className="flex items-center justify-between flex-shrink-0 p-2">
          <span className="p-2 text-xl font-semibold leading-8 tracking-wider uppercase whitespace-nowrap">
            <img src={Logo} alt="logo"/>
          </span>

          <button
            onClick={toggleSidebar}
            className="p-2 rounded-md focus:outline-none focus:ring lg:hidden"
          >
            <svg
              className={`w-6 h-6 text-white mr-4 ${
                isSidebarOpen
                  ? "transform transition-transform -rotate-180"
                  : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 5l7 7-7 7M5 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>

        <nav className="flex-1 overflow-hidden hover:overflow-y-auto">
          <ul className="p-2 overflow-hidden">{linksToShow}</ul>
        </nav>

        <div className="flex-shrink-0 p-2 border-t max-h-14">
          <button
            className="font-mono flex items-center justify-center w-full px-4 py-2 space-x-1 
            font-medium tracking-wider uppercase border border-secondary text-white
             rounded-md focus:outline-none text-lg"
            onClick={handleLogout}
          >
            <svg
              className="w-6 h-6 mr-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
            Logout
            <span className={!isSidebarOpen ? "hidden" : "Logout"}></span>
          </button>
        </div>
      </aside>
    </div>
  );

  return (
    <div>
      <div className="lg:hidden">{sidebarContent}</div>
      <div className="hidden lg:flex lg:flex-shrink-0">{sidebarContent}</div>
    </div>
  );
}

export default Sidebar;
