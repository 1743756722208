import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import BasicModal from "./BasicModal";
import { useAuth } from "../../contextAPI/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditOrdersModal = ({ open, closeModal, orderId }) => {
  const {
    getOrderById,
    editOrders,
    authState,
    fetchLaundryitems,
    fetchLaundryTypes,
  } = useAuth();
  const { loading, availableLaundryItems, availableLaundryTypes } = authState;

  const [customerData, setCustomerData] = useState({
    status: "",
    laundryItems: [],
    paymentType: "",
    amountPaid: "",
  });
  const [amountPaid, setAmountPaid] =useState()

  // Fetch order details by orderId
  const fetchOrder = async () => {
    if (orderId) {
      const response = await getOrderById(orderId);
      if (response.success) {
        const { status, customer, laundryItems, paymentType,amountPaid } = response.data.order;
        setCustomerData({
          ...customer,
          status,
          laundryItems,
          paymentType,
          amountPaid
        });
      } else {
        toast.error(response.msg);
      }
    }
  };

  // Fetch laundry items and types on component mount
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchLaundryitems(token);
      fetchLaundryTypes(token);
    }
  }, [fetchLaundryitems, fetchLaundryTypes]);

  // Fetch order details when modal opens or orderId changes
  useEffect(() => {
    if (open && orderId) {
      fetchOrder();
    }
  }, [open, orderId]);

  // Handle input change for order status
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...customerData, [name]: value });
  };
  
  // Handle change for laundry item selection
  const handleItemChange = (e, index) => {
    const { value } = e.target;
    // Create a copy of laundryItems array
    const updatedLaundryItems = [...customerData.laundryItems];
    // Update the specific laundry item with the new item value
    updatedLaundryItems[index] = {
      ...updatedLaundryItems[index],
      item: { ...updatedLaundryItems[index].item, item: value },
    };
    // Update state with the new laundryItems array
    setCustomerData({
      ...customerData,
      laundryItems: updatedLaundryItems,
    });
  };

  // Handle change for laundry service type selection
  const handleTypeChange = (e, index) => {
    const { value } = e.target;
    // Create a copy of laundryItems array
    const updatedLaundryItems = [...customerData.laundryItems];
    // Update the specific laundry item with the new type value
    updatedLaundryItems[index] = {
      ...updatedLaundryItems[index],
      type: { ...updatedLaundryItems[index].type, type: value },
    };
    // Update state with the new laundryItems array
    setCustomerData({
      ...customerData,
      laundryItems: updatedLaundryItems,
    });
  };

  // Handle change for quantity input
  const handleQuantityChange = (e, index) => {
    const { value } = e.target;
    // Create a copy of laundryItems array
    const updatedLaundryItems = [...customerData.laundryItems];
    // Update the specific laundry item with the new quantity value
    updatedLaundryItems[index] = {
      ...updatedLaundryItems[index],
      quantity: value,
    };
    // Update state with the new laundryItems array
    setCustomerData({
      ...customerData,
      laundryItems: updatedLaundryItems,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      status: customerData.status,
      laundryItems: customerData.laundryItems,
      paymentType: customerData.paymentType,
      amountPaid: customerData.amountPaid
    };
    const response = await editOrders(orderId, payload);

    if (response.success) {
      toast.success(response.msg);
      setTimeout(() => {
        closeModal();
      }, 2000); // Adjust the delay as needed
    } else {
      toast.error(response.msg);
    }
  };

  return (
    <BasicModal open={open} closeModal={closeModal}>
      <div className="flex justify-between border-b-2 mb-2 pb-5 p-5 font-mono">
        <h2>Update Order Details</h2>
        <button onClick={closeModal}>
          <AiOutlineClose />
        </button>
      </div>

      <div className="bg-white p-2 transition-shadow lg:w-full md:block w-80">
        <ToastContainer position="top-right" autoClose={3000} />
        <div className="bg-white p-4 transition-shadow border rounded-md shadow-sm hover:shadow-lg">
          <form className="p-3 font-mono" onSubmit={handleSubmit}>
            <div className="mb-4 flex gap-4">
              <div className="w-full">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium text-gray-700"
                >
                  Order Status
                </label>
                <div className="flex mt-1">
                  <select
                    id="status"
                    name="status"
                    value={customerData?.status}
                    onChange={handleChange}
                    required
                    className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                  >
                    <option value="">Select order status</option>
                    <option value="pending">Pending</option>
                    <option value="in-progress">In progress</option>
                    <option value="completed">Completed</option>
                    {/* <option value="delivered">Delivered</option> */}
                  </select>
                </div>
              </div>
            </div>

            <div className="mb-4 flex gap-4">
              <div className="w-full">
                <label
                  htmlFor="paymentType"
                  className="block text-sm font-medium text-gray-700"
                >
                  Payment Plan
                </label>
                <div className="flex mt-1">
                  <select
                    id="paymentType"
                    name="paymentType"
                    value={customerData?.paymentType}
                    onChange={handleChange}
                    required
                    className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                  >
                    <option value="">Select payment plan</option>
                    <option value="full payment">Full Payment</option>
                    <option value="part payment">Part Payment</option>
                    <option value="no payment">No payment</option>
                  </select>
                </div>
              </div>
            </div>

            {(customerData?.paymentType === "part payment" ) && (
                        <div className="w-full">
                          <label
                            htmlFor="amountpaid"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Amount Paid
                          </label>
                          <div className="flex mt-1">
                            <button
                              type="button"
                              className="px-3 text-white py-1 bg-primary text-black rounded-l-md focus:outline-none"
                            >
                              &#x20A6;
                            </button>
                            <input
                              type="number"
                              id="amountpaid"
                              name="amountpaid"
                              value={customerData?.amountPaid}
                              onChange={(e) => setCustomerData({ ...customerData, amountPaid: e.target.value })}
                              className="px-3 py-1 border rounded-r-md w-full border-green-300 focus:outline-none"
                            />
                          </div>
                        </div>
                      )}

                      
                    
            
            {/* <div>
              {customerData?.laundryItems?.map((laundryItem, index) => (
                <div key={index} className="mb-4 flex gap-4">
                  <div className="w-1/2">
                    <label
                      htmlFor={`laundryItem-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Laundry Item {index + 1}
                    </label>
                    <div className="flex mt-1">
                      <select
                        id={`laundryItem-${index}`}
                        value={laundryItem?.item?.item}
                        onChange={(e) => handleItemChange(e, index)}
                        className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 cursor-not-allowed bg-gray-100"
                      
                      >
                        {availableLaundryItems?.map((item) => (
                          <option key={item._id} value={item.item}>
                            {item.item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <label
                      htmlFor={`laundryService-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Laundry Service {index + 1}
                    </label>
                    <div className="flex mt-1">
                      <select
                        id={`laundryService-${index}`}
                        value={laundryItem?.type?.type}
                        onChange={(e) => handleTypeChange(e, index)}
                        className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 cursor-not-allowed bg-gray-100"
                        
                      >
                        {availableLaundryTypes?.map((type) => (
                          <option key={type._id} value={type.type}>
                            {type.type}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <label
                      htmlFor={`laundryQty-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Qty of Item {index + 1}
                    </label>
                    <div className="flex mt-1">
                      <input
                        type="text"
                        id={`laundryQty-${index}`}
                        value={laundryItem.quantity}
                        onChange={(e) => handleQuantityChange(e, index)}
                        className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                        placeholder="Enter the laundry quantity"
                        required
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
            <button
              type="submit"
              className="py-2 px-4 rounded-md bg-primary w-full text-white mt-4"
              disabled={loading}
            >
              {loading ? "Loading..." : "Edit order details"}
            </button>
          </form>
        </div>
      </div>
    </BasicModal>
  );
};

export default EditOrdersModal;
