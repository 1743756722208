import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import BasicModal from "./BasicModal";
import { useAuth } from "../../contextAPI/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddStaffModal = ({ open, closeModal }) => {
  const { addStaff, authState } = useAuth();
  const { loading } = authState;
  const [showPassword, setShowPassword] = useState(false);
  const [staffData, setStaffData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStaffData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, phoneNumber, password, confirmPassword } = staffData;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid Email format");
      return;
    }

    if (phoneNumber.length !== 11) {
      toast.error("Phone number must be 11 digits");
      return;
    }
    if (password.length < 6) {
      toast.error("Password must be at least 6 characters");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    try {
      const response = await addStaff(staffData);

      if (response.msg === "Staff created successfully") {
        toast.success(response.msg, {
          onClose: () => {
            closeModal();
            setStaffData({ // Clear the form after successfully adding a customer
              fullName: "",
              email: "",
              phoneNumber: "",
              password: "",
              confirmPassword: "",
            });
          }
        });
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error(error.response.msg || "An error occurred");
    }
  };

  return (
    <BasicModal open={open} closeModal={closeModal}>
      <div className="flex justify-between border-b-2 mb-2 pb-5 p-5 font-mono">
        <h2>Add New Staff</h2>
        <button onClick={closeModal}>
          <AiOutlineClose />
        </button>
      </div>

      <div className="bg-white p-2 transition-shadow lg:w-full md:block w-80">
        <ToastContainer position="top-right" autoClose={3000} />
        <div className="bg-white p-4 transition-shadow border rounded-md shadow-sm hover:shadow-lg">
          <form className="p-3 font-mono" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="staffName"
                className="block text-sm font-medium text-gray-700"
              >
                Staff Name
              </label>
              <input
                type="text"
                id="staffName"
                name="fullName"
                value={staffData.fullName}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                placeholder="Enter the staff name"
                required
              />
            </div>

            <div className="mb-4 flex gap-4">
              <div className="w-1/2">
                <label
                  htmlFor="customerName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <div className="flex mt-1">
                  <input
                    type="number"
                    id="customerName"
                    name="phoneNumber"
                    value={staffData.phoneNumber}
                    onChange={handleChange}
                    className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                    placeholder="Enter the staff phone number"
                    required
                  />
                </div>
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="emailAddress"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
                <div className="flex mt-1">
                  <input
                    type="text"
                    id="emailAddress"
                    name="email"
                    value={staffData.email}
                    onChange={handleChange}
                    className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                    placeholder="Enter the staff email address"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="mb-4 flex gap-4">
              <div className="w-1/2">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="relative mt-1">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={staffData.password}
                    onChange={handleChange}
                    className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                    placeholder="Enter the staff password"
                    required
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="absolute inset-y-0 right-0 flex items-center pr-3 mt-4 cursor-pointer"
                    onClick={togglePasswordVisibility}
                    style={{ color: "#028795" }}
                  />
                </div>
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm Password
                </label>
                <div className="relative mt-1">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="confirmPassword"
                    name="confirmPassword"
                    value={staffData.confirmPassword}
                    onChange={handleChange}
                    className="mt-1 p-2 border rounded-md w-full border-green-300 hover:border-green-500 focus:outline-none"
                    placeholder="Confirm the staff password"
                    required
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="absolute inset-y-0 right-0 flex items-center pr-3 mt-4 cursor-pointer"
                    onClick={togglePasswordVisibility}
                    style={{ color: "#028795" }}
                  />
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="py-2 px-4 rounded-md bg-primary w-full text-white"
              disabled={loading}
            >
              {loading ? "Loading..." : "Add a new staff"}
            </button>
          </form>
        </div>
      </div>
    </BasicModal>
  );
};

export default AddStaffModal;
