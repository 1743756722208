import React, { useState, useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import BasicModal from "./BasicModal";
import { useAuth } from "../../contextAPI/AuthContext"; // Adjust path as per your file structure
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useReactToPrint } from "react-to-print";

const GenerateReceipts = ({ open, closeModal, orderId }) => {
  const { getOrderById } = useAuth();
  const [customerData, setCustomerData] = useState({});
  const [generatedOrderId, setGeneratedOrderId] = useState("");

  const fetchOrder = async () => {
    if (orderId) {
      const response = await getOrderById(orderId);
      if (response.success) {
        setCustomerData(response?.data?.order);

        const storedGeneratedOrderId = localStorage.getItem(
          `generatedOrderId_${orderId}`
        );
        setGeneratedOrderId(storedGeneratedOrderId || "");
      } else {
        toast.error(response.msg);
      }
    }
  };

  useEffect(() => {
    if (open && orderId) {
      fetchOrder();
    }
  }, [open, orderId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString();
  };

  const readyDateOrder = formatDate(customerData?.readyDate);

  // Calculate subtotal for each item
  const subtotalsItems = customerData?.laundryItems?.map(
    (orderDetail) => orderDetail.price * orderDetail.quantity
  );

  // Calculate total amount before discount
  const totalBeforeDiscount = subtotalsItems?.reduce(
    (acc, subtotal) => acc + subtotal,
    0
  );
  const discountPercentage = parseFloat(customerData?.discount) || 0;
  const discountAmount = (totalBeforeDiscount * discountPercentage) / 100;
  const totalAfterDiscount = totalBeforeDiscount - discountAmount;


  const subtotals = customerData?.totalAmount;
  const formattedTotalWithVAT = subtotals?.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  const formattedAdditionalFee = customerData?.additionalFee?.toLocaleString(
    "en-NG",
    {
      style: "currency",
      currency: "NGN",
    }
  );
  const formattedLogisticsFee = customerData?.logisticFee?.toLocaleString(
    "en-NG",
    {
      style: "currency",
      currency: "NGN",
    }
  );

  const formattedAmountPaid = customerData?.amountPaid?.toLocaleString(
    "en-NG",
    {
      style: "currency",
      currency: "NGN",
    }
  );
  const formattedtotalAfterDiscount = totalAfterDiscount?.toLocaleString(
    "en-NG",
    {
      style: "currency",
      currency: "NGN",
    }
  );
  const formattedDiscount= discountAmount.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
  });
  
  const subTotalAdditionalAndLogistics =
    Number(customerData?.additionalFee || 0) +
    Number(customerData?.logisticFee || 0);

    const OutstandingBalance = 
    Number(customerData?.totalAmount) -
    Number(customerData?.amountPaid || 0);  


  const formattedAdditionalAndLogistics =
    subTotalAdditionalAndLogistics.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN",
    });
    const formattedOutstandingBalance = OutstandingBalance.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN",
    });
  
  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <BasicModal open={open} closeModal={closeModal}>
      <div className="flex justify-between border-b-2 mb-2 pb-5 p-5">
        <h2
          className="py-2 px-4 rounded-md text-sm bg-primary text-white cursor-pointer"
          onClick={handlePrint}
        >
          Print Receipts
        </h2>
        <button onClick={closeModal}>
          <AiOutlineClose />
        </button>
      </div>
      <div
        className="bg-white p-2 transition-shadow lg:w-full md:block w-80"
        ref={componentRef}
      >
        <div className="relative">
          <div className="absolute inset-x-0 top-0 bg-primary h-2 w-full rounded-t-lg"></div>
          <div className="bg-white p-4 transition-shadow border rounded-md shadow-sm hover:shadow-lg">
            <div className="flex justify-center text-lg capitalize font-bold mt-1">
              swifttouch global services limited
              {/* <img src={Logo} alt="logo" className="w-48" /> */}
            </div>
            <div className="flex justify-between text-xs mt-2 italic">
              <div>
                56b Boundary Street Shasha/Akowonjo,
                <br />
                Lagos, Nigeria
              </div>
              <div className="uppercase">{generatedOrderId}</div>
            </div>
            <div className="flex justify-between font-semibold mt-4 capitalize text-sm">
              <div>customer name</div>
              <div></div>
              <div>{customerData?.customer?.fullName?.slice(0, 15)}</div>
            </div>
            <div className="flex justify-between font-bold mt-4 uppercase">
              <div className="text-sm">cash receipt</div>
              <div></div>
              <div className="text-sm">{customerData?.dropOffDate}</div>
            </div>
            <p className="text-center asterisk-line">
              * * * * * * * * * * * * * * * * * * * * * * * * *
            </p>

            <div className="flex justify-between text-sm font-bold">
              <div>Laundry Item</div>
              <div></div>
              <div>Price</div>
            </div>
            {customerData?.laundryItems?.map((item) => (
              <div
                key={item?.item?._id}
                className="flex justify-between text-xs mt-1"
              >
                <div>{item?.item?.item}</div>
                <div></div>
                <div>
                  {formatNumberWithCommas(item?.price)} x {item?.quantity}
                </div>
              </div>
            ))}
            <div className="flex justify-between text-xs mt-4">
              <div>Discount({customerData?.discount}%)</div>
              <div></div>
              {formattedDiscount}
            </div>
            <div className="flex justify-between text-sm font-bold">
              <div>Subtotal</div>
              <div></div>
              {formattedtotalAfterDiscount}
            </div>
            <p className="text-center asterisk-line mt-4">
              * * * * * * * * * * * * * * * * * * * * * * * * *
            </p>

            <div className="flex justify-between text-xs mt-1">
              <div>Payment Method</div>
              <div></div>
              <div className="capitalize">{customerData?.paymentMethod}</div>
            </div>
            <div className="flex justify-between text-xs mt-1">
              <div>Additional Fee</div>
              <div></div>
              <div className="capitalize">{formattedAdditionalFee ? formattedAdditionalFee : "₦0.00"}</div>
            </div>
            <div className="flex justify-between text-xs mt-1">
              <div>Logistics Fee</div>
              <div></div>
              <div className="capitalize"> {formattedLogisticsFee ? formattedLogisticsFee : "₦0.00"}</div>
            </div>

            <div className="flex justify-between text-sm font-bold">
              <div>Subtotal</div>
              <div></div>
              {formattedAdditionalAndLogistics}
            </div>
            <div className="flex justify-between font-bold text-lg mt-4">
              <div>Total</div>
              <div></div>
              <div>{formattedTotalWithVAT}</div>
            </div>
            {(customerData?.paymentType === "part payment" || customerData?.paymentType === "no payment") && (
         <>
          <div className="flex justify-between text-sm font-bold">
            <div>Amount Paid</div>
            <div></div>
            <div className="">
              {formattedAmountPaid ? formattedAmountPaid : "₦0.00"}
            </div>
          </div>
          <div className="flex justify-between text-sm font-bold">
            <div>Outstanding Balance</div>
            <div></div>
            <div className="capitalize">
             {formattedOutstandingBalance ? formattedOutstandingBalance : "₦0.00"}
            </div>
          </div>
             
          </>
          
        )}
            <p className="text-center asterisk-line mt-4">
              * * * * * * * * * * * * * * * * * * * * * * * * *
            </p>
            <div className="flex justify-between text-sm capitalize font-bold mt-2">
              <div>Pickup Date</div>
              <div></div>
              <div>{readyDateOrder}</div>
            </div>
            <div className="flex justify-between capitalize text-sm mt-2 font-bold">
              <div>Attended by: </div>
              <div></div>
              <div>{customerData?.enteredBy?.fullName?.slice(0, 15)}</div>
            </div>

            <div className="uppercase italic text-xs mt-4 font-bold">
              <p>Opening hours</p>
              <p>Monday - Saturday: 8:00am - 5:00pm</p>
            </div>
            <h3 className="uppercase font-bold text-lg text-center mt-2">
              Thank you!
            </h3>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};

export default GenerateReceipts;
