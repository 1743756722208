import { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import BasicModal from "./BasicModal";
import { useAuth } from "../../contextAPI/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeleteCustomersModal = ({ open, closeModal, customerId }) => {
  const { deleteCustomer, authState } = useAuth();
  const { loading} = authState;
  const [customerData, setCustomerData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    address: "",
    city: "",
    lga: "",
  });

  useEffect(() => {
    if (customerId) {
      const customer = authState.allcustomers.customers.find(
        (c) => c._id === customerId
      );
      if (customer) {
        setCustomerData(customer);
      }
    }
  }, [customerId, authState.allcustomers]);



  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await deleteCustomer(customerId, customerData);
    if (response.msg) {
      toast.success(response.msg);
      setTimeout(() => {
        closeModal();
      }, 2000); // Adjust the delay as needed
    }
  };

  return (
    <BasicModal open={open} closeModal={closeModal}>
      {/* <ToastContainer position="top-right" autoClose={3000} /> */}
      <div className="flex justify-between border-b-2 mb-2 pb-5 p-5 font-mono">
        <h2>Delete Customer Details</h2>
        <button onClick={closeModal}>
          <AiOutlineClose />
        </button>
      </div>

      <div className="bg-white p-2 transition-shadow lg:w-full md:block w-80">
        <ToastContainer position="top-right" autoClose={3000} />
        <div className="bg-white p-4 transition-shadow border rounded-md shadow-sm hover:shadow-lg">
          <div className="mb-4 flex gap-4">
            <h3 className="text-sm">
              Are you sure you want to delete this customer {" "}
              <span className="font-bold capitalize">"{customerData.fullName}"</span> from the database?
            </h3>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-end">
            <button
              type="submit"
              className="py-2 px-4 rounded-md bg-red-500 mt-4 text-white text-sm"
              disabled={loading}
            >
              {loading ? "Loading..." : "Delete customer details"}
            </button>
          </div>
        </form>
      </div>
    </BasicModal>
  );
};

export default DeleteCustomersModal;
