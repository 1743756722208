import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import AddLaundryItemsModal from "../../components/Modals/AddLaundryItemsModal";
import EditLaundryItemModal from "../../components/Modals/EditLaundryItemModal";
import EditLaundryTypeModal from "../../components/Modals/EditLaundryTypeModal";
import { useAuth } from "../../contextAPI/AuthContext";
import Papa from "papaparse";
import { saveAs } from "file-saver";

const Categories = () => {
  const [isAddLaundryItemOpen, setIsLaundryItemOpen] = useState(false);
  const [isEditLaundryItemOpen, setIsEditLaundryItemOpen] = useState(false);
  const [isEditLaundryTypeOpen, setIsEditLaundryTypeOpen] = useState(false);
  const [selectedLaundryItem, setSelectedLaundryItem] = useState(null);
  const [selectedLaundryType] = useState(null);
  const { authState, fetchLaundryTypes, fetchLaundryitems } = useAuth();
  const { laundryItems } = authState;
  const [reloadCustomers, setReloadCustomers] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 30;

  // Filter items based on the search query
  const filteredItems = laundryItems?.filter((item) =>
    item.item.toLowerCase().includes(searchQuery.toLowerCase())
  ) || [];

  // Calculate pagination based on filtered items
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const openLaundryItemModal = () => {
    setIsLaundryItemOpen(true);
  };

  const closeModal = () => {
    setIsLaundryItemOpen(false);
    setIsEditLaundryItemOpen(false);
    setIsEditLaundryTypeOpen(false);
    setReloadCustomers((prev) => !prev);
  };

  const openEditLaundryItemModal = (item) => {
    setSelectedLaundryItem(item);
    setIsEditLaundryItemOpen(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchLaundryTypes(token);
      fetchLaundryitems(token);
    }
  }, [fetchLaundryTypes, fetchLaundryitems, reloadCustomers]);

  const downloadCSV = () => {
    const csvData = laundryItems.map((item, index) => ({
      "S/N": index + 1,
      "Laundry Item": item.item,
      Price: item.price,
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Swifttouchlaundryitem.csv");
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset page to 1 when search query changes
  };

  return (
    <div className="flex font-mono">
      <div className="flex flex-col flex-1">
        <main className="flex-1 max-h-full p-5 overflow-hidden overflow-y-scroll">
          {/* Main content header */}
          <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row">
            <h1 className="text-2xl font-semibold whitespace-nowrap text-primary">
              Add Categories
            </h1>
            <div className="space-y-6 md:space-x-2 md:space-y-0">
              <div
                className="cursor-pointer inline-flex items-center justify-center px-4 py-2 space-x-1 bg-primary 
              text-white rounded-md shadow hover:bg-opacity-20"
                onClick={openLaundryItemModal}
              >
                <span>
                  <span>
                    <svg
                      className="w-4 h-4 text-white"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 0C3.58 0 0 3.58 0 8c0 4.42 3.58 8 8 8 4.42 0 8-3.58 8-8 0-4.42-3.58-8-8-8zm4 9H9v3H7V9H4V7h3V4h2v3h3v2z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </span>
                <span>Add Laundry Items</span>
              </div>
              <AddLaundryItemsModal
                open={isAddLaundryItemOpen}
                closeModal={closeModal}
              />
            </div>
          </div>
          <div className="filter-container p-4 flex justify-between items-center overflow-x-auto mt-2">
            {/* Left side: Search input and Download CSV button */}
            <div className="flex items-center space-x-4">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search..."
                className="rounded-md border-2 border-primary p-2 focus:border-primary
                focus:outline-none focus:ring-1"
              />
            </div>

            <div className="flex justify-end">
              <div
                className="cursor-pointer inline-flex items-center justify-center text-primary
   px-4 py-2 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20"
                onClick={downloadCSV}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      strokeWidth="2"
                      fill="transparent"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 6v6m0 0v6m0-6l-4-4m4 4l4-4"
                    />
                  </svg>
                </span>
                <span>Download CSV</span>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-5 mt-6">
            <div className="col-span-12 lg:col-span-12">
              <div className="flex flex-col space-y-1 pb-3">
                <div className="border-2 border-primary self-center rounded-full p-2">
                  <Icon className="text-xl text-primary" icon="bxs:user" />
                </div>
                <div className="text-primary text-center">
                  View Laundry Items
                </div>
              </div>
              <div className="relative">
                <div className="absolute inset-x-0 top-0 bg-primary h-2 w-full rounded-t-lg"></div>
                <div className="bg-white p-4 transition-shadow border rounded-md shadow-sm hover:shadow-lg">
                  <div className="flex flex-wrap gap-2 mt-2">
                    {currentItems?.map((item) => (
                      <div
                        key={item._id}
                        className="border-2 border-primary rounded-md px-2 py-1 cursor-pointer mt-2 "
                        onClick={() => openEditLaundryItemModal(item)}
                      >
                        {`${item.item} - ${item.price.toLocaleString("en-NG", {
                          style: "currency",
                          currency: "NGN",
                        })}`}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Pagination controls */}
          <div className="flex justify-center mt-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-4 py-2 mx-1 bg-primary text-white rounded disabled:opacity-50"
              >
                Previous
              </button>
              {[...Array(totalPages).keys()].map((number) => (
                <button
                  key={number + 1}
                  onClick={() => handlePageChange(number + 1)}
                  className={`px-4 py-2 mx-1 ${
                    currentPage === number + 1
                      ? "bg-primary text-white"
                      : "bg-gray-200"
                  } rounded`}
                >
                  {number + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-4 py-2 mx-1 bg-primary text-white rounded disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </main>
        </div>
        <EditLaundryItemModal
          open={isEditLaundryItemOpen}
          closeModal={closeModal}
          laundryItem={selectedLaundryItem}
        />
        <EditLaundryTypeModal
          open={isEditLaundryTypeOpen}
          closeModal={closeModal}
          laundryItem={selectedLaundryType}
        />
      </div>
    );
  };
  
  export default Categories;
  
