import React, { useState, useEffect } from "react";
import { useAuth } from "../../contextAPI/AuthContext";

const Greeting = () => {
  const [greeting, setGreeting] = useState("");
  const { authState, fetchUserProfile } = useAuth();
  const { user } = authState;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchUserProfile(token);
    }
  }, [fetchUserProfile]);

  const getGreeting = () => {
    const now = new Date();
    const hours = now.getHours();

    if (!user || !user.fullName) {
      return "Loading...";
    }

    if (hours < 12) {
      return `Good Morning, ${user.fullName}`;
    } else if (hours < 18) {
      return `Good Afternoon, ${user.fullName}`;
    } else {
      return `Good Evening, ${user.fullName}`;
    }
  };

  useEffect(() => {
    // Set the initial greeting
    setGreeting(getGreeting());

    // Update the greeting every minute
    const timer = setInterval(() => {
      setGreeting(getGreeting());
    }, 60000); // 60000 milliseconds = 1 minute

    // Clear the interval when the component is unmounted
    return () => clearInterval(timer);
  }, [user]); // Add user to the dependency array

  return (
    <div className="text-sm font-bold text-primary bg-white rounded-full shadow px-4 py-2">
      {greeting}
    </div>
  );
};

export default Greeting;
