import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import LoginPage from "./pages/authentication/Login";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import ResetPassword from "./pages/authentication/ResetPassword";
import ResendOTP from "./pages/authentication/ResendOTP";
import Index from "./pages/dashboard/Index";
import Layout from "./components/dashboard/LayoutProps";
import CreateOrders from "./pages/dashboard/CreateOrders";
import Notifications from "./pages/dashboard/Notifications";
import Customers from "./pages/dashboard/Customers";
import ViewOrders from "./pages/dashboard/ViewOrders";
import UserActivity from "./pages/dashboard/UserActivity";
import ModalTest from "./pages/ModalTest";
import OutstandingBalance from "./pages/dashboard/OutstandingBalance";


const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/resend-otp" element={<ResendOTP />} />
        <Route path="/modal-test" element={<ModalTest />} />
     
          <Route path="/dashboard/*" element={<Layout />}>
            <Route index element={<Index />} />
            <Route path="create-order" element={<CreateOrders />} />
            <Route path="customers" element={<Customers />} />
            <Route path="notification" element={<Notifications />} />
            <Route path="view-orders" element={<ViewOrders />} />
            <Route path="user-activity" element={<UserActivity />} />
            <Route path="outstanding-balance" element={<OutstandingBalance />} />


          </Route>
  
          <Route path="/*" element={<Navigate to="/" />} /> 
    
      </Routes>
    </Router>
  );
};

export default App;
