import React from "react";


const UserActivity = () => {

 

  return (
    <div className="flex font-mono">
      <div className="flex flex-col flex-1">
        <main className="flex-1 max-h-full p-5 overflow-hidden overflow-y-scroll">
          {/* Main content header */}
          <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row">
            <h1 className="text-2xl font-semibold whitespace-nowrap text-primary">
              View Employees
            </h1>
            <div className="space-y-6 md:space-x-2 md:space-y-0">
              <div className="cursor-pointer inline-flex items-center justify-center px-4 
              py-2 space-x-1 bg-primary text-white rounded-md shadow hover:bg-opacity-20">
                <span>
                  <span>
                    <svg
                      className="w-4 h-4 text-white"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 0C3.58 0 0 3.58 0 8c0 4.42 3.58 8 8 8 4.42 0 8-3.58 8-8 0-4.42-3.58-8-8-8zm4 9H9v3H7V9H4V7h3V4h2v3h3v2z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </span>
                <span>Add a new staff</span>
              </div>
            </div>
          </div>
       
        </main>
      </div>
    </div>
  );
};

export default UserActivity;
