import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Greeting from "./Greeting";
import DateComponent from "./DateComponent";
import { useAuth } from "../../contextAPI/AuthContext";
import Logo from "../../assets/logo/swifttouch-logo.png"

function Header() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const { authState, fetchUserProfile } = useAuth();
  const { user } = authState;

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleSearchBox = () => {
    setIsSearchBoxOpen(!isSearchBoxOpen);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchUserProfile(token);
    }
  }, [fetchUserProfile]);
  
  return (
    <>
      <header className="flex-shrink-0 border-b ">
        <div className="flex items-center justify-between p-2">
          {/* Navbar left */}
          <div className="flex items-center space-x-3">
            <span
              className="p-2 text-xl font-semibold tracking-wider uppercase lg:hidden"
              onClick={toggleSidebar}
            >
              SWIFT TOUCH
            </span>
            <div className="lg:hidden">
              {isSidebarOpen && (
                <Sidebar
                  isSidebarOpen={isSidebarOpen}
                  toggleSidebar={toggleSidebar}
                />
              )}
            </div>
            {/* Toggle sidebar button */}

            <button
              onClick={toggleSidebar}
              className="p-2 rounded-md focus:outline-none focus:ring lg:hidden"
            >
              <svg
                className="w-6 h-6 text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Search box */}
          <div className="hidden px-2 space-x-2 md:flex-1 md:flex md:mr-auto md:ml-2">
            <Greeting />
           
          </div>

          {/* Navbar right */}
          <div className="relative flex items-center space-x-3 hidden md:block md:mr-0">
            {/* Search button */}

            <button
              onClick={toggleSearchBox}
              className="p-2 bg-gray-100 rounded-full md:hidden focus:outline-none focus:ring hover:bg-gray-200"
            >
              <svg
                className="w-6 h-6 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button>
            {/* Additional Navbar items */}
            <div className="flex items-center justify-end">
             

              <div className="relative mr-2">
                <div
                  className="relative"
                  onClick={() => setProfileOpen(!profileOpen)}
                >
                  <button className="p-1 bg-gray-200 rounded-full focus:outline-none focus:ring">
                    <img
                      className="object-cover w-8 h-8 rounded-full"
                      src={Logo}
                      alt="logo"
                    />
                  </button>
                  {/* green dot */}
                  <div className="absolute right-0 p-1 bg-primary rounded-full bottom-3 animate-ping"></div>
                  <div className="absolute right-0 p-1 bg-primary border border-white rounded-full bottom-3"></div>
                  {/* Dropdown card */}

                  {profileOpen && (
                    <div className="absolute mt-1 transform -translate-x-full bg-white rounded-md shadow-lg min-w-max">
                      <div className="flex flex-col p-4 space-y-1 font-mono border-b px-8">
                        <span className="text-gray-800 capitalize font-bold">
                        {user?.fullName}
                        </span>
                        <span className="text-sm text-gray-400">
                          {/* {userrole === "ROLE_ADMIN" ? "Librarian" : "User Account"} */}
                        </span>
                      </div>

                      <div className="font-mono flex items-center justify-center p-4 text-primary underline-none border-t cursor-pointer">
                        {/* Edit Profile */}
                      Role:<span className="capitalize">{user?.role}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
               <DateComponent/>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        {/* Mobile search box */}
        {isSearchBoxOpen && (
          <div
            className="fixed inset-0 z-10 bg-black bg-opacity-20"
            style={{
              backdropFilter: "blur(14px)",
              WebkitBackdropFilter: "blur(14px)",
            }}
          >
            <div
              onClick={toggleSearchBox}
              className="absolute inset-x-0 flex items-center justify-between p-2 bg-white shadow-md"
            >
              <div className="flex items-center flex-1 px-2 space-x-2">
                <span>
                  <svg
                    className="w-6 h-6 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full px-4 py-3 text-gray-600 rounded-md focus:bg-gray-100 focus:outline-none"
                />
              </div>
              <button
                onClick={toggleSearchBox}
                className="flex-shrink-0 p-4 rounded-md"
              >
                <svg
                  className="w-4 h-4 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
      </header>
    </>
  );
}

export default Header;
