import React, { useEffect } from "react";
import Logo from "../../assets/logo/swifttouch-logo.png";
import { useAuth } from "../../contextAPI/AuthContext";

const PreviewInvoice = ({
  orderDetails,
  pickupDate,
  dropOffDate,
  customerType,
  selectedCustomer,
  paymentMethod,
  discount,
  vat,
  amountPaid,
  paymentType,
  additionalFee,
  logisticFee,
}) => {
  // Calculate subtotal for each item
  const subtotals = orderDetails.map(
    (orderDetail) => orderDetail.costPrice * orderDetail.quantity
  );

  // Calculate total amount before discount
  const totalBeforeDiscount = subtotals.reduce(
    (acc, subtotal) => acc + subtotal,
    0
  );

  // Apply discount
  const discountPercentage = parseFloat(discount) || 0;
  const discountAmount = (totalBeforeDiscount * discountPercentage) / 100;
  const totalAfterDiscount = totalBeforeDiscount - discountAmount;

  // Apply VAT
  const totalWithVAT = totalAfterDiscount * (1 + vat / 100);
    // Add additional fees and logistics fees to the total
    const totalWithFees = 
    Number(totalWithVAT) + 
    Number(additionalFee || 0) + 
    Number(logisticFee || 0)  

    const OutstandingBalance = 
    Number(totalWithFees) -
    Number(amountPaid || 0);  


  // Format totalWithVAT with commas and two decimal places
  const formattedTotalWithVAT = totalWithFees.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  const formattedtotalAfterDiscount = totalAfterDiscount.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  const formattedOutstandingBalance = OutstandingBalance.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  const subTotalAdditionalAndLogistics = 
  Number(additionalFee || 0) + 
  Number(logisticFee || 0) 

  const formattedAdditionalAndLogistics= subTotalAdditionalAndLogistics.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
  });
  const formattedDiscount= discountAmount.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
  });


  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const { authState, fetchUserProfile } = useAuth();
  const { user } = authState;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchUserProfile(token);
    }
  }, [fetchUserProfile]);

  return (
    <div className="relative">
      <div className="absolute inset-x-0 top-0 bg-primary h-2 w-full rounded-t-lg"></div>
      <div className="bg-white p-4 transition-shadow border rounded-md shadow-sm hover:shadow-lg">
        <div className="flex justify-center">
          <img src={Logo} alt="logo" className="w-32" />
        </div>
        <p className="text-center asterisk-line">
          * * * * * * * * * * * * * * * * * * * * * *{" "}
        </p>
        <div className="flex justify-between font-bold mt-4 uppercase">
          <div className="text-lg">cash receipt</div>
          <div></div>
          <div>{dropOffDate}</div>
        </div>
        <p className="text-center asterisk-line">
          * * * * * * * * * * * * * * * * * * * * * *{" "}
        </p>
        <div className="flex justify-between text-sm font-bold">
          <div>Laundry Item</div>
          <div></div>
          <div>Price</div>
        </div>
        {orderDetails.map((orderDetail) => (
          <div
            key={orderDetail.id}
            className="flex justify-between text-sm mt-1"
          >
            <div>{orderDetail.selectedLaundryItem?.label}</div>
            <div></div>
            <div>
              {formatNumberWithCommas(orderDetail.costPrice)} x{" "}
              {orderDetail.quantity}
            </div>
          </div>
        ))}
          <div className="flex justify-between text-xs mt-4">
          <div>Discount ({discount}%)</div>
          <div></div>
          <div>{formattedDiscount}</div>
        </div>
        <div className="flex justify-between text-sm font-bold">
          <div>Subtotal</div>
          <div></div>
          <div>{formattedtotalAfterDiscount}</div>
        </div>
        <p className="text-center asterisk-line mt-4">
          * * * * * * * * * * * * * * * * * * * * * *{" "}
        </p>
        <div className="flex justify-between text-xs mt-2">
          {/* <div>Vat</div>
          <div></div>
          <div>{vat}%</div> */}
        </div>{" "}
        <div className="flex justify-between text-xs">
          <div>Payment Method</div>
          <div></div>
          <div className="capitalize">{paymentMethod}</div>
        </div>
      
     
        <div className="flex justify-between text-xs">
          <div>Additional Fee</div>
          <div></div>
          <div>
          {additionalFee && !isNaN(Number(additionalFee))
                ? Number(additionalFee).toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })
                : "₦0.00"}
          </div>
        </div>
        <div className="flex justify-between text-xs">
          <div>Logistics Fee</div>
          <div></div>
          <div>
          {logisticFee && !isNaN(Number(logisticFee))
                ? Number(logisticFee).toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })
                : "₦0.00"}
          </div>
        </div>
      
        <div className="flex justify-between text-sm font-bold">
          <div>Subtotal</div>
          <div></div>
          <div>{formattedAdditionalAndLogistics}</div>
        </div>
       
        <div className="flex justify-between font-bold text-lg mt-4">
          <div>Total</div>
          <div></div>
          <div>{formattedTotalWithVAT}</div>
        </div>
        {(paymentType === "part payment" || paymentType === "no payment") && (
         <>
          <div className="flex justify-between text-sm font-bold">
            <div>Amount Paid</div>
            <div></div>
            <div className="capitalize">
              {" "}
              {amountPaid && !isNaN(Number(amountPaid))
                ? Number(amountPaid).toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })
                : "₦0.00"}
            </div>
          </div>
          <div className="flex justify-between text-sm font-bold">
            <div>Outstanding Balance</div>
            <div></div>
            <div className="capitalize">
             {formattedOutstandingBalance}
            </div>
          </div>
          
          </>
          
        )}
        <p className="text-center asterisk-line mt-4">
          * * * * * * * * * * * * * * * * * * * * * *{" "}
        </p>
        <div className="flex justify-between text-sm capitalize font-bold mt-2">
          <div>Pickup Date</div>
          <div></div>
          <div>{pickupDate}</div>
        </div>
        <div className="flex justify-between capitalize text-sm mt-2 font-bold">
          <div>Attended by:</div>
          <div></div>
          <div>{user?.fullName}</div>
        </div>
        <h3 className="uppercase font-bold text-lg text-center mt-2">
          Thank you!
        </h3>
      </div>
    </div>
  );
};

export default PreviewInvoice;
